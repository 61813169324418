import React from 'react'

const FAQ = () => {

    const genfaqs = [
        {
            question: "What is Pa-Help?",
            answer: " Pa-Help is a hyperlocal cross-platform app designed to connect individuals who need assistance with simple tasks to local helpmates. It leverages for real-time communication."
        },
        {
            question: "How does Pa-Help work?",
            answer: "Users can post tasks they need help with, and local helpmates can browse available tasks and offer their assistance. The app facilitates communication, task tracking, and secure payments."
        },
        {
            question: "How do I post a task on Pa-Help?",
            answer: "To post a task, simply create an account, log in, and click on the Post a Task button. Fill out the details of your task, including the description, location, and any specific requirements. Once submitted, local helpmates will be able to view and offer their assistance."
        },
        {
            question: "How do I find someone to help me with my task?",
            answer: "After posting your task, you can browse through offers from local helpmates. You can view their profiles, ratings, and reviews to choose the best person for the job."
        },
        {
            question: "Is my payment secure?",
            answer: "Yes, Pa-Help prioritizes secure and straightforward transactions. While Pa-Help allows face-to-face payments for tasks, it is up to the users to decide if they want to transact payments through e-payments based on their agreements. We recommend conducting any face-to-face payments in a safe and public location to ensure both parties' safety and satisfaction."
        },
    ]

    const hfaqs = [
        {
            question: "How do I sign up to become a helpmate?",
            answer: "To become a helpmate, download the Pa-Help app, create an account, and complete your profile. Make sure to include your skills, availability, and any relevant experience. Once your profile is set up, you can start browsing tasks and offering your assistance."
        },
        {
            question: "How do I get paid for completing tasks?",
            answer: " Once you complete a task and the client confirms its completion, the payment will be made directly to you by the client according to your agreed-upon payment method, which can include face-to-face payments or other arrangements."
        },
        {
            question: "What types of tasks can I assist with?",
            answer: "Helpmates can assist with a wide range of tasks, including grocery shopping, cleaning, handyman services, and more. You can browse tasks that match your skills and availability."
        },
    ]

    const tfaqs = [
        {
            question: "What platforms is Pa-Help available on?",
            answer: "Pa-Help is available on Web and Mobile Android devices. You can download and access the app through Google Play Store or the Amazon App Store and on your browser."
        },
        {
            question: "How does Pa-Help ensure the quality and reliability of helpmates?",
            answer: "Pa-Help includes a rating and review system where clients can provide feedback on their experience with helpmates."
        },
        {
            question: "What if I encounter an issue with the app?",
            answer: "If you encounter any issues with the app, you can contact our support team through the in-app help section or email us at synthxgordoncollege@gmail.com. We are here to assist you with any problems you may face."
        },
    ]


    return (
        <div className='container mt-4'>
            <div className="card">
                <div className="card-header h3 text-warning">
                    Frequently Asked Questions
                </div>
                <div className="card-body">
                    <h4 className='mb-3'>General FAQs</h4>
                    <div className="accordion mb-4" id="accordionPanelsStayOpenExample">
                        {genfaqs.map((faq, index) => (
                            <div className="accordion-item" key={index}>
                                <h2 className="accordion-header">
                                    <button
                                        className={`accordion-button ${index === 0 ? '' : 'collapsed'}`}
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#panelsStayOpen-collapse${index}`}
                                        aria-expanded={index === 0 ? "true" : "false"}
                                        aria-controls={`panelsStayOpen-collapse${index}`}
                                    >
                                        Q. {faq.question}
                                    </button>
                                </h2>
                                <div
                                    id={`panelsStayOpen-collapse${index}`}
                                    className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                                >
                                    <div className="accordion-body">
                                        A. {faq.answer}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <h4 className='mb-3'>Helpmates FAQs</h4>
                    <div className="accordion mb-4" id="accordionPanelsStayOpenExample">
                        {hfaqs.map((faq, index) => (
                            <div className="accordion-item" key={index}>
                                <h2 className="accordion-header">
                                    <button
                                        className={`accordion-button ${index === 0 ? '' : 'collapsed'}`}
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#panelsStayOpen-collapse${index}`}
                                        aria-expanded={index === 0 ? "true" : "false"}
                                        aria-controls={`panelsStayOpen-collapse${index}`}
                                    >
                                        Q. {faq.question}
                                    </button>
                                </h2>
                                <div
                                    id={`panelsStayOpen-collapse${index}`}
                                    className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                                >
                                    <div className="accordion-body">
                                        A. {faq.answer}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <h4 className='mb-3'>Technical FAQs</h4>
                    <div className="accordion mb-4" id="accordionPanelsStayOpenExample">
                        {tfaqs.map((faq, index) => (
                            <div className="accordion-item" key={index}>
                                <h2 className="accordion-header">
                                    <button
                                        className={`accordion-button ${index === 0 ? '' : 'collapsed'}`}
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#panelsStayOpen-collapse${index}`}
                                        aria-expanded={index === 0 ? "true" : "false"}
                                        aria-controls={`panelsStayOpen-collapse${index}`}
                                    >
                                        Q. {faq.question}
                                    </button>
                                </h2>
                                <div
                                    id={`panelsStayOpen-collapse${index}`}
                                    className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                                >
                                    <div className="accordion-body">
                                        A. {faq.answer}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FAQ