import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "./components.scss"
import Swal from 'sweetalert2';
import Select from 'react-select';
import {
    regions,
    provinces,
    cities,
    barangays,
} from 'select-philippines-address';

function SetProfile(props) {

    const { setIsEdit, loading, setLocCity, userRecentWork, setUserRecentWork, userData, setUserName, setUSerBio, setUserNumber, setUserLocation, setIsProfileSet, userPhoto, userBio, userName, userEmail, userNumber, userLocation, handleSaveChanges } = props;
    const [regionOptions, setRegionOptions] = useState([]);
    const [provinceOptions, setProvinceOptions] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [barangayOptions, setBarangayOptions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [selectedProvince, setSelectedProvince] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedBrgy, setSelectedBrgy] = useState(null);
    const [userRegion, setUserRegion] = useState(null);
    const [userProv, setUserProv] = useState(null);
    const [userCity, setUserCity] = useState(null);
    const [userBrgy, setUserBrgy] = useState(null);
    const [userLot, setUserLot] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        regions().then(response => {
            const options = response.map(region => ({
                value: region.region_code,
                label: region.region_name,
            }));
            setRegionOptions(options);
        });
    }, []);

    useEffect(() => {
        if (selectedRegion) {
            provinces(selectedRegion.value).then(response => {
                const options = response.map(province => ({
                    value: province.province_code,
                    label: province.province_name,
                }));
                setUserRegion(selectedRegion.label);
                console.log(userRegion)
                setProvinceOptions(options);
                setCityOptions([]);
                setBarangayOptions([]);
                setSelectedProvince(null);
                setSelectedCity(null);
            });
        }
    }, [selectedRegion]);

    useEffect(() => {
        if (selectedProvince) {
            cities(selectedProvince.value).then(response => {
                const options = response.map(city => ({
                    value: city.city_code,
                    label: city.city_name,
                }));
                setUserProv(selectedProvince.label);
                console.log(userRegion)
                setCityOptions(options);
                setBarangayOptions([]);
                setSelectedCity(null);
            });
        }
    }, [selectedProvince]);

    useEffect(() => {
        if (selectedCity) {
            setUserCity(selectedCity.label);
            barangays(selectedCity.value).then(response => {
                const options = response.map(barangay => ({
                    value: barangay.brgy_code,
                    label: barangay.brgy_name,
                }));
                setBarangayOptions(options);
                console.log(userCity)
            });
        }
    }, [selectedCity]);

    useEffect(() => {
        if (selectedBrgy) {
            setUserBrgy(selectedBrgy.label);
            console.log(userCity)
        }
    }, [selectedBrgy]);

    const handleSubmit = (e) => {
        console.log()
        e.preventDefault()
        if (checkInputs()) {
            console.log(checkInputs())
            setLocCity(userCity)
            setUserLocation(`${userLot}, ${userBrgy}, ${userCity}, ${userProv}, ${userRegion}`)
            console.log("handlesubmit userloc", userLocation)
            // Trigger SweetAlert confirmation dialog
            Swal.fire({
                title: 'Are you sure?',
                text: 'Do you want to save user information?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes, save it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true,
                confirmButtonColor: '#F9AA33',
                cancelButtonColor: '#f44336',
            }).then((result) => {
                if (result.isConfirmed) {
                    // Proceed with form submission
                    submitForm();
                    setIsProfileSet(1);
                }
            });
        } else {
            console.log(checkInputs())
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Fill all fields",
            });
        }
    };

    const submitForm = () => {
        // Your form submission logic here
        setLocCity(userCity)
        setUserLocation(`${userLot}, ${userBrgy}, ${userCity}, ${userProv}, ${userRegion}`)
        console.log("submitform userloc", userLocation)
        handleSaveChanges();
        console.log('Form submitted!');
    };

    const checkInputs = () => {
        console.log("userName:", userName);
        console.log("userNumber:", userNumber);
        console.log("userBio:", userBio);
        console.log("selectedRegion:", selectedRegion);
        console.log("selectedProvince:", selectedProvince);
        console.log("selectedCity:", selectedCity);
        console.log("selectedBrgy:", selectedBrgy);
        console.log("userLot:", userLot);

        // Set the user location
        // const location = `${userRegion}, ${userProv}, ${userCity}, ${userBrgy}`;
        // setUserLocation(location);
        // console.log("user location: ", userLocation)

        return (
            userName != null && userName.trim() !== '' &&
            userNumber != null && userNumber !== '' &&
            userBio != null && userBio.trim() !== '' &&
            selectedRegion != null &&
            selectedProvince != null &&
            selectedCity != null &&
            selectedBrgy != null &&
            userLot != null
        );
    };

    const handleCancel = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You are about to cancel the process.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F9AA33',
            cancelButtonColor: '#f44336',
            confirmButtonText: 'Yes, cancel it!',
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Cancelled!',
                    'Your action has been cancelled.',
                    'success'
                );
                setIsEdit(false);
                navigate(-1)
            }
        });
    }

    return (
        <div className='container'>
            {
                loading ?
                    <div className="loader">
                        <div className="loader-text">Loading...</div>
                        <div className="loader-bar"></div>
                    </div>
                    :
                    <form class="row g-3 needs-validation" novalidate>
                        <div className='container mt-5'>
                            <div className="card p-3 shadow">

                                <div class="card-header mb-3 text-warning h3">
                                    Edit Profile
                                </div>

                                <div className="row">
                                    <div className="col-2">
                                        <img src={userPhoto} class="" alt="..." style={{ objectFit: "cover", width: "100%" }} />
                                    </div>
                                    <div className="col-10">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div class="form-floating mb-3">
                                                            <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com" value={userName} onChange={(e) => setUserName(e.target.value)} required />
                                                            <label for="floatingInput">User Name</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div class="form-floating mb-3">
                                                            <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" value={userEmail} readOnly />
                                                            <label for="floatingInput">Email address</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div class="form-floating mb-3">
                                                            <input type="number" class="form-control hide-number-arrows" id="floatingInput" placeholder="name@example.com" value={userNumber} onChange={(e) => setUserNumber(e.target.value)} required />
                                                            <label for="floatingInput">Contact Number</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="row">
                                                    <div className="col-12">
                                                        {userRecentWork ?
                                                            <>
                                                                {userRecentWork.map((work, index) => (
                                                                    <div className="form-floating mb-3" key={index}>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id={`floatingInput-${index}`}
                                                                            placeholder={`Recent Work ${index + 1}`}
                                                                            value={work}
                                                                            onChange={(e) => {
                                                                                const updatedRecentWork = [...userRecentWork];
                                                                                updatedRecentWork[index] = e.target.value;
                                                                                setUserRecentWork(updatedRecentWork);
                                                                            }}
                                                                            required
                                                                        />
                                                                        <label htmlFor={`floatingInput-${index}`}>{`Recent Work ${index + 1}:`}</label>
                                                                    </div>
                                                                ))}
                                                            </>
                                                            :
                                                            <>
                                                                {[...Array(3)].map((_, index) => (
                                                                    <div className="form-floating mb-3" key={index}>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id={`floatingInput-${index}`}
                                                                            placeholder={`Recent Work ${index + 1}`}
                                                                            value=""
                                                                            onChange={(e) => {
                                                                                const updatedRecentWork = Array.from({ length: 3 }, (_, i) =>
                                                                                    i === index ? e.target.value : (userRecentWork && userRecentWork[i]) || ""
                                                                                );
                                                                                setUserRecentWork(updatedRecentWork);
                                                                            }}
                                                                            required
                                                                        />
                                                                        <label htmlFor={`floatingInput-${index}`}>{`Recent Work ${index + 1}:`}</label>
                                                                    </div>
                                                                ))}
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-6 mb-3">
                                        <div className="form-floating">
                                            <select className="form-select" id="floatingSelect" aria-label="Floating label select example" onChange={(e) => {
                                                const selectedOption = regionOptions.find(option => option.value === e.target.value);
                                                setSelectedRegion(selectedOption);
                                            }} required>
                                                <option selected disabled>Choose a region</option>
                                                {regionOptions.map((option, index) => (
                                                    <option key={index} value={option.value}>{option.label}</option>
                                                ))}
                                            </select>
                                            <label htmlFor="floatingSelect">Select a region</label>
                                        </div>
                                    </div>
                                    <div className="col-6 mb-3">
                                        <div className="form-floating">
                                            <select className="form-select" id="floatingSelect" aria-label="Floating label select example" onChange={(e) => {
                                                const selectedOption = provinceOptions.find(option => option.value === e.target.value);
                                                setSelectedProvince(selectedOption);
                                            }} required>
                                                <option selected disabled>Choose a province</option>
                                                {provinceOptions.map((option, index) => (
                                                    <option key={index} value={option.value}>{option.label}</option>
                                                ))}
                                            </select>
                                            <label htmlFor="floatingSelect">Select a province</label>
                                        </div>
                                    </div>
                                    <div className="col-6 mb-3">
                                        <div className="form-floating">
                                            <select className="form-select" id="floatingSelect" aria-label="Floating label select example" onChange={(e) => {
                                                const selectedOption = cityOptions.find(option => option.value === e.target.value);
                                                setSelectedCity(selectedOption);
                                            }} required>
                                                <option selected disabled>Choose a city/municipality</option>
                                                {cityOptions.map((option, index) => (
                                                    <option key={index} value={option.value}>{option.label}</option>
                                                ))}
                                            </select>
                                            <label htmlFor="floatingSelect">Select a city/municipality</label>
                                        </div>
                                    </div>
                                    <div className="col-6 mb-3">
                                        <div className="form-floating">
                                            <select className="form-select" id="floatingSelect" aria-label="Floating label select example" onChange={(e) => {
                                                const selectedOption = barangayOptions.find(option => option.value === e.target.value);
                                                setSelectedBrgy(selectedOption);
                                            }} required>
                                                <option selected disabled>Choose a baranggay</option>
                                                {barangayOptions.map((option, index) => (
                                                    <option key={index} value={option.value}>{option.label}</option>
                                                ))}
                                            </select>
                                            <label htmlFor="floatingSelect">Select a baranggay</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com" value={userLot} onChange={(e) => setUserLot(e.target.value)} required />
                                            <label for="floatingInput">Lot No. and Street</label>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <div class="form-floating">
                                            <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea" required style={{ maxHeight: "200px" }} value={userBio} onChange={(e) => setUSerBio(e.target.value)} ></textarea>
                                            <label for="floatingTextarea">User Bio</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 d-flex justify-content-between">
                                    <button class="btn btn-warning" type="submit" onClick={handleSubmit}>Save User Information</button>
                                    <button class="btn btn-secondary" type="button" onClick={handleCancel}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </form>
            }
        </div>
    );
}

export default SetProfile;
