import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from "../components/Navbar"

const Layout = () => {
    return (
        <div className='container-fluid p-0 m-0'>
            <Navbar />
            <Outlet />

        </div>
    )
}

export default Layout