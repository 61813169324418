import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { collection, addDoc, Timestamp, doc, getDoc, updateDoc } from 'firebase/firestore';
import { database } from '../firebase.js';
import { UserAuth } from '../context/AuthContext';
import './pages.scss';
import Sidebar from '../components/Sidebar.jsx';
import Header from '../components/Header.jsx';
import { IoLocationSharp, IoChevronBackCircleSharp } from "react-icons/io5";
import Swal from 'sweetalert2';


function AddUpdateJobs() {
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState(null);
    const [taskId, setTaskId] = useState('');
    const [taskTitle, setTaskTitle] = useState('');
    const [taskDescription, setTaskDescription] = useState('');
    const [taskPrice, setTaskPrice] = useState('');
    const [taskStatus, setTaskStatus] = useState('');
    const [taskLoc, setTaskLoc] = useState('');
    const [taskDate, setTaskDate] = useState('');
    const [taskDeadline, setTaskDeadline] = useState('');
    const [taskCateg, setTaskCateg] = useState('');
    const [isHourMin, setIsHourMin] = useState('minutes');
    const [bool, setBool] = useState(true);
    const [open, setOpen] = useState(false);

    const categoriesList = [
        'Pa-bili',
        'Pa-hatid',
        'Pa-linis',
        'Pa-bantay',
    ];

    const handleOpen = () => {
        setOpen(!open)
    }

    // Function to format the date
    const formatTaskDate = (timestamp) => {
        const currentDate = new Date();
        const taskDate = new Date(timestamp.seconds * 1000);

        // Calculate the time difference in milliseconds
        const timeDifference = currentDate - taskDate;
        const options = { month: 'short', day: 'numeric', year: 'numeric' };
        return taskDate.toLocaleDateString('en-US', options);
    };

    // Fetch user info
    useEffect(() => {
        const userId = sessionStorage.getItem('userId');

        const fetchUserInfo = async () => {
            if (userId) {
                const userDocRef = doc(collection(database, 'accounts'), userId);
                const userDocSnap = await getDoc(userDocRef);
                if (userDocSnap.exists()) {
                    setUserInfo(userDocSnap.data());
                    console.log(userInfo)
                }
            }
        };

        fetchUserInfo();
    }, []);

    // Check for taskId in sessionStorage
    useEffect(() => {
        const storedTaskId = sessionStorage.getItem('taskId');

        const fetchTaskDetails = async () => {
            if (storedTaskId != null) {
                try {
                    // Fetch task details from Firestore based on taskId
                    const taskDocRef = storedTaskId ? doc(collection(database, 'tasks'), storedTaskId) : null;
                    const taskDocSnap = await getDoc(taskDocRef);

                    if (taskDocSnap.exists()) {
                        const taskData = taskDocSnap.data();

                        // Assuming you have state variables like taskTitle, taskDescription, taskPrice
                        setTaskId(taskData.task_id);
                        setTaskTitle(taskData.task_title || '');
                        setTaskDescription(taskData.task_description || '');
                        setTaskPrice(taskData.task_price || 0);
                        setTaskStatus(taskData.task_status || '');
                        setTaskLoc(taskData.task_location || '');
                        setTaskDate(taskData.date_created || '');
                        setTaskCateg(taskData.task_category || '');
                        setTaskDeadline(taskData.task_deadline || '');
                        setIsHourMin(taskData.task_hourmin || '')
                    }
                } catch (error) {
                    console.error('Error fetching task details:', error);
                }
            }
        };
        fetchTaskDetails();

        // Clear the taskId from sessionStorage (optional)
        return () => {
            sessionStorage.removeItem('taskId');
        };
    }, []);

    const handleCancel = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F9AA33',
            cancelButtonColor: '#f44336',
            confirmButtonText: 'Yes, cancel it!',
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Cancelled!',
                    'Your action has been cancelled.',
                    'success'
                );
                navigate('/errandoffers');
            }
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (checkInputs()) {
            Swal.fire({
                title: 'Are you sure?',
                text: `You are about to ${taskId ? 'update' : 'add'} this task.`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#F9AA33',
                cancelButtonColor: '#f44336',
                confirmButtonText: 'Yes, do it!',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire(
                        'Success!',
                        `Task has been ${taskId ? 'updated' : 'added'}.`,
                        'success'
                    );
                    handleAddUpdateTask();
                }
            });
        } else {
            console.log(checkInputs())
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Fill all fields",
            });
        }
        
        sessionStorage.removeItem('taskId');
    };

    // Handle add/update task
    const handleAddUpdateTask = async () => {
        // Create a new task document in Firestore or update existing task
        const taskData = {
            task_author_id: userInfo.user_id,
            task_author: userInfo.user_name,
            task_author_email: userInfo.user_email,
            task_title: taskTitle,
            task_description: taskDescription,
            task_location: userInfo.user_location,
            task_price: taskPrice,
            task_status: "Open",
            task_category: taskCateg,
            task_worker: null,
            task_worker_id: null,
            task_worker_email: null,
            task_deadline: taskDeadline,
            task_hourmin: isHourMin,
            date_created: Timestamp.now(),
            is_active: true,
        };

        try {
            if (taskId) {
                // If taskId exists, update the existing task
                await updateDoc(doc(collection(database, 'tasks'), taskId), taskData);// Show alert
                console.log('Successfully Updated');
            } else {
                // If no taskId, add a new task
                const docRef = await addDoc(collection(database, 'tasks'), taskData);
                // Update the task_id field with the generated document ID
                await updateDoc(docRef, { task_id: docRef.id });// Show alert
                console.log('Successfully Added');
            }

            // Redirect to the job offers page after adding/updating the task
            navigate('/errandoffers');
        } catch (error) {
            Swal.fire(
                'error!',
                `Error ${taskId ? 'updated' : 'added'} task.`,
                'error'
            );
            console.error('Error adding/updating task: ', error);
        }
    };

    const handleCategoryChange = (e) => {
        setTaskCateg(e.target.value);
    };

    const handleClickCategoryChange = (e) => {
        setTaskCateg(e);
    };

    const handleIsHourMin = () => {
        setBool(!bool)
        if (bool) {
            setIsHourMin("hours")
        } else {
            setIsHourMin("minutes")
        }
    }

    const checkInputs = () => {
        console.log("task_title:", taskTitle);
        console.log("task_description:", taskDescription);
        console.log("task_price:", taskPrice);
        console.log("task_category:", taskCateg);
        console.log("task_deadline:", taskDeadline);
        console.log("task_hourmin:", isHourMin);

        return (
            taskTitle != null && taskTitle.trim() !== '' &&
            taskDescription != null && taskDescription.trim() !== '' &&
            taskPrice != null && taskPrice !== '' &&
            taskCateg != null && taskCateg.trim() !== '' &&
            taskDeadline != null && taskDeadline !== ''
        );
    };

    return (
        <>
            <div className="container mb-5">
                <form action="" className="row border shadow rounded-2 mt-4 needs-validation p-0 m-2" novalidate>
                    <div className="col-12 h3 bg-warning m-0 py-3 px-4 text-light">
                        {taskId ? 'Update' : 'Add'} Simple Errand
                    </div> <hr />
                    <div className="col-12 py-3 px-4">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" id="floatingInput" value={taskTitle} onChange={(e) => setTaskTitle(e.target.value)} required />
                                    <label for="floatingInput">Title</label>
                                </div>
                                <div class="form-floating">
                                    <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea" required style={{ maxHeight: "200px", minHeight: "150px" }} value={taskDescription} onChange={(e) => setTaskDescription(e.target.value)}></textarea>
                                    <label for="floatingTextarea">Description</label>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" id="floatingInput" value={taskId ? taskStatus : 'Open'} disabled />
                                    <label for="floatingInput">Status</label>
                                </div>
                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" id="floatingInput" value={taskId ? taskLoc : (userInfo ? userInfo.user_location : '----')} disabled />
                                    <label for="floatingInput">Location</label>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6">
                                        <div class="form-floating mb-3">
                                            <input type="number" class="form-control" id="floatingInput" value={taskPrice} onChange={(e) => setTaskPrice(e.target.value)} required />
                                            <label for="floatingInput">Budget (PHP)</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <div class="input-group mb-3">
                                            <div class="form-floating">
                                                <input type="number" class="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="button-addon2" value={taskDeadline} onChange={(e) => setTaskDeadline(e.target.value)} required />
                                                <label for="floatingInputGroup1">Estimated Time</label>
                                            </div>
                                            <button class="btn btn-outline-secondary" type="button" id="button-addon2" onClick={handleIsHourMin}>{isHourMin}</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div class="input-group mb-3">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" id="floatingInputGroup1" placeholder="Username" value={taskCateg} onChange={handleCategoryChange} required />
                                            <label for="floatingInputGroup1">Category</label>
                                        </div>
                                        <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
                                        <ul class="dropdown-menu dropdown-menu-end">
                                            {categoriesList.map((category) => (
                                                <li key={category} onClick={() => handleClickCategoryChange(category)} style={{ cursor: "pointer" }}>{category}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12 d-flex justify-content-end mt-5">
                                    <button className="btn btn-secondary me-3" type='button' onClick={handleCancel}>Cancel</button>
                                    <button className='btn btn-warning' type='submit' onClick={handleSubmit}>{taskId ? 'Update Task' : 'Add Task'}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form >
            </div >
        </>
    );
}

export default AddUpdateJobs;
