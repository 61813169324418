import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext'
import logo from "../assets/Pa-help-logo-name.png"
import { NavLink, Link } from 'react-router-dom';
import { LuClipboardEdit, LuClipboardPaste } from 'react-icons/lu'
import { BsChatSquareText } from 'react-icons/bs'
import { TbWorldSearch } from 'react-icons/tb'
import { MdLogout } from 'react-icons/md'
import { IoChevronBackCircle } from "react-icons/io5";
import { AiOutlineFileProtect } from "react-icons/ai";
import { BsFileEarmarkLock2 } from "react-icons/bs";
import { IoIosMenu } from "react-icons/io";
import { TiThMenu } from "react-icons/ti";
import { ImProfile } from "react-icons/im";
import { LuFilePlus2 } from "react-icons/lu";
import { FaQuestion } from "react-icons/fa";
import { LuFolderLock } from "react-icons/lu";

const Navbar = () => {
    const { logout, user } = UserAuth();
    const [isClient, setIsClient] = useState();
    const [isAdd, setIsAdd] = useState();

    const SidebarData1 = [

        {
            title: "My Task Offers",
            path: "/errandoffers",
            icon: <LuClipboardEdit size={20} />,
        },
    ]

    const SidebarData0 = [

        {
            title: "Tasks Hub",
            path: "/allerrands",
            icon: <TbWorldSearch size={20} />,
        },
        {
            title: "Applied Tasks",
            path: "/appliederrands",
            icon: <LuClipboardPaste size={20} />,
        },
    ]

    const SidebarData2 = [
        {
            title: "Terms and Conditions",
            path: "/termsAndConditionRead",
            icon: <AiOutlineFileProtect size={20} />,
        },
        {
            title: "Privacy Policy",
            path: "/privacyPolicyReadOnly",
            icon: <BsFileEarmarkLock2 size={20} />,
        },
        {
            title: "Data Privacy Policy",
            path: "/dataPrivacyPolicy",
            icon: <LuFolderLock size={20} />,
        },
        {
            title: "FAQ",
            path: "/faq",
            icon: <FaQuestion size={20} />,
        },

    ]

    const navigate = useNavigate();
    const handleAddTask = (bool) => {
        sessionStorage.setItem('isAdd', bool);
        navigate('/addupdatejob');
    };

    useEffect(() => {
        const storedIsClient = sessionStorage.getItem('isClient');
        const storedIsAdd = sessionStorage.getItem('isAdd');
        setIsAdd(storedIsAdd)
        console.log(isAdd)
        if (storedIsClient !== null) {
            setIsClient(parseInt(storedIsClient));
        }
    }, [isAdd]);

    const changeRole = (value) => {
        sessionStorage.setItem('isClient', value);
        setIsClient(value);
    };

    const handleSignOut = async () => {
        try {
            await logout()
        } catch (error) {
            console.log(error)
        }
    }

    const handleChangeRole = (value, path) => {
        changeRole(value)
        navigate(path)
    }
    return (
        <>
            <nav className="navbar navbar-expand-lg bg-body-tertiary shadow sticky-top">
                <div className="container-fluid">
                    <button class="btn btn-light p-0 " type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><TiThMenu className='fs-3' /></button>

                    <img src={logo} alt='logo' className='' style={{ height: "2rem" }} />
                </div>
            </nav>
            <div class="offcanvas offcanvas-start" data-bs-backdrop="static" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div class="offcanvas-header">
                    <img src={logo} alt='logo' className='' style={{ height: "2rem" }} />
                    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body container-fluid">
                    <div className="row p-2">
                        {
                            isClient ?
                                <>
                                    <span className="col-6 btn fs-4 text-light bg-warning rounded-pill border-2" onClick={() => handleChangeRole(1, "/errandoffers")}>Help Seeker</span>
                                    <span className="col-6 btn fs-4 text-muted rounded-pill " onClick={() => handleChangeRole(0, "/allerrands")} data-bs-dismiss="offcanvas" aria-label="Close">Helpmate</span>
                                </>
                                :
                                <>
                                    <span className="col-6 btn fs-4 text-muted rounded-pill " onClick={() => handleChangeRole(1, "/errandoffers")} data-bs-dismiss="offcanvas" aria-label="Close">Help Seeker</span>
                                    <span className="col-6 btn fs-4 text-light bg-warning rounded-pill border-" onClick={() => handleChangeRole(0, "/allerrands")}>Helpmate</span>
                                </>
                        }
                    </div> <hr />
                    <div data-bs-dismiss="offcanvas" aria-label="Close">
                        <NavLink to="/profile" className='text-decoration-none row text-black' aria-disabled="true">
                            <div className='col-2 fs-5 text-center'><ImProfile size={20} /></div>
                            <div id="Profile" className="col-10 fs-5 p-0">Profile</div>
                        </NavLink>
                    </div> <hr />
                    {
                        isClient ?
                            <>
                                {
                                    SidebarData1.map((item, index) => {
                                        return (
                                            <div key={index} className='' data-bs-dismiss="offcanvas" aria-label="Close">
                                                <NavLink to={item.path} className='text-decoration-none row text-black mb-3' aria-disabled="true">
                                                    <div className='col-2 fs-5 text-center'>{item.icon}</div>
                                                    <div id={item.title} className="col-10 fs-5 p-0">{item.title}</div>
                                                </NavLink>
                                            </div>
                                        )
                                    })
                                }
                            </>
                            :
                            <>
                                {
                                    SidebarData0.map((item, index) => {
                                        return (
                                            <div key={index} className='' data-bs-dismiss="offcanvas" aria-label="Close">
                                                <NavLink to={item.path} className='text-decoration-none row text-black mb-3' aria-disabled="true">
                                                    <div className='col-2 fs-5 text-center'>{item.icon}</div>
                                                    <div id={item.title} className="col-10 fs-5 p-0">{item.title}</div>
                                                </NavLink>
                                            </div>
                                        )
                                    })
                                }
                            </>
                    } <hr />
                    {
                        SidebarData2.map((item, index) => {
                            return (
                                <div key={index} className='' data-bs-dismiss="offcanvas" aria-label="Close">
                                    <NavLink to={item.path} className='text-decoration-none row text-black mb-3' aria-disabled="true">
                                        <div className='col-2 fs-5 text-center'>{item.icon}</div>
                                        <div id={item.title} className="col-10 fs-5 p-0">{item.title}</div>
                                    </NavLink>
                                </div>
                            )
                        })
                    } <hr />
                    <div className='row text-black' aria-disabled="true" onClick={handleSignOut} style={{ cursor: "pointer" }}>
                        <div className='col-2 fs-5 text-center'><MdLogout size={20} /></div>
                        <div className='col-10 fs-5 p-0'>Logout</div>
                    </div>

                </div>
            </div >

            <button
                type="button"
                className={`btn btn-warning btn-floating btn-lg bg-none ${isClient ? "d-flex" : "d-none"} justify-content-center align-items-center rounded-circle p-3 `}
                onClick={() => handleAddTask(1)}
            >
                <LuFilePlus2 className='m-0' />
            </button>
        </>
    )
}

export default Navbar