import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import './pages.scss';
import Sidebar from '../components/Sidebar.jsx'
import Header from '../components/Header.jsx';
import { doc, getDoc, query, collection, getDocs, where, updateDoc, writeBatch, deleteDoc, setDoc, Timestamp, arrayUnion } from 'firebase/firestore';
import { database } from '../firebase.js';
import { IoLocationSharp, IoChevronBackCircleSharp } from "react-icons/io5";
import { Rating } from 'react-simple-star-rating';
import emailjs from "@emailjs/browser";
import Swal from 'sweetalert2';



function ViewJobOffers() {
    const [taskDetails, setTaskDetails] = useState(null);
    const [taskApplicants, setTaskApplicants] = useState([]);
    const [isWorker, setIsWorker] = useState();
    const navigate = useNavigate();
    const [rating, setRating] = useState(0);
    const [review, setReview] = useState('');
    const [open, setOpen] = useState(false);
    const [hasRated, setHasRated] = useState(false);
    const [loading, setLoading] = useState(false);

    // Catch Rating value
    const handleRating = (rate) => {
        if (review == "") {
            Swal.fire(
                'Warning!',
                `Please enter your review.`,
                'warning'
            );

        } else {
            Swal.fire({
                title: 'Are you sure?',
                text: `You are about rate this user.`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#F9AA33',
                cancelButtonColor: '#f44336',
                confirmButtonText: 'Yes',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    handleRate(rate)
                }
            });
        };
        console.log('rate: ', rate)
    }

    const handleRate = async () => {

        try {
            const raterName = sessionStorage.getItem('username');
            const rateeId = taskDetails.task_worker_id;
            const dateRated = Timestamp.now();
            const ratingCateg = taskDetails.task_category;
            const ratingTaskId = taskDetails.task_id;

            // Create a new rating document in Firebase
            const ratingDocRef = await setDoc(doc(collection(database, 'ratings')), {
                rater_name: raterName,
                ratee_id: rateeId,
                rating_value: rating,
                review: review,
                rating_categ: ratingCateg,
                date_rated: dateRated,
                rating_task_id: ratingTaskId,
            });
            AddskillonRate(rateeId, ratingCateg, rating);
            setRating(0);
            setReview('');
            Swal.fire(
                'Success!',
                `Successfully Rated.`,
                'success'
            );
        } catch (error) {
            Swal.fire(
                'Error!',
                `Error submitting rating.`,
                'error'
            );
            console.error('Error submitting rating:', error);
        } finally {
            navigate("/errandoffers")
        }
    };

    const clickStar = (rate) => {
        setRating(rate)
        console.log(rate)
    }

    const AddskillonRate = async (id, ratingCateg, ratingVal) => {
        const userDocRef = doc(database, "accounts", id);

        try {
            // Fetch the current document data
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists()) {
                const userData = userDoc.data();

                // Check if user_skills exists, if not initialize it as an empty array
                let updatedSkills = userData.user_skills ? [...userData.user_skills] : [];

                // Create a new skill object
                const newSkill = { [ratingCateg]: ratingVal };

                // Add the new skill to the user_skills array
                console.log('Updated skills before adding:', updatedSkills);
                updatedSkills.push(newSkill);
                console.log('Updated skills after adding:', updatedSkills);


                // Update the user document with the new user_skills array
                await updateDoc(userDocRef, {
                    user_skills: updatedSkills
                });

                console.log("User skills updated successfully.");
            } else {
                console.log("User document not found.");
            }
        } catch (error) {
            console.error("Error updating user skills:", error);
        }
    };

    const handleReviewChange = (e) => {
        setReview(e.target.value);
    };

    const handleOpen = () => {
        setOpen(!open)
    }

    // Function to format the date
    const formatTaskDate = (timestamp) => {
        const currentDate = new Date();
        const taskDate = new Date(timestamp.seconds * 1000);

        // Calculate the time difference in milliseconds
        const timeDifference = currentDate - taskDate;

        // If less than 1 minute ago, display "Just now"
        if (timeDifference < 60 * 1000) {
            return 'Just now';
        } else if (timeDifference < 60 * 60 * 1000) {
            // If less than 1 hour ago, display "X minutes ago"
            const minutesAgo = Math.floor(timeDifference / (60 * 1000));
            return `${minutesAgo} ${minutesAgo === 1 ? 'minute' : 'minutes'} ago`;
        } else if (timeDifference < 24 * 60 * 60 * 1000) {
            // If less than 24 hours ago, display "X hours ago"
            const hoursAgo = Math.floor(timeDifference / (60 * 60 * 1000));
            return `${hoursAgo} ${hoursAgo === 1 ? 'hour' : 'hours'} ago`;
        } else {
            // Otherwise, display the date in the format "Mon. DD, YYYY"
            const options = { month: 'short', day: 'numeric', year: 'numeric' };
            return taskDate.toLocaleDateString('en-US', options);
        }
    };

    useEffect(() => {
        const checkRating = async () => {
            setLoading(true)
            try {
                const raterName = sessionStorage.getItem('username');
                const ratingTaskId = taskDetails.task_id;

                // Query the ratings collection to check if the user has already rated the task
                const ratingsQuery = query(
                    collection(database, 'ratings'),
                    where('rater_name', '==', raterName),
                    where('rating_task_id', '==', ratingTaskId)
                );

                const ratingsSnapshot = await getDocs(ratingsQuery);

                // If there is at least one rating document, the user has rated the task
                setHasRated(!ratingsSnapshot.empty);
            } catch (error) {
                console.error('Error checking rating:', error);
            } finally {
                setLoading(false)
            }
        };

        // Check the rating when the component mounts
        if (taskDetails) {
            checkRating();
        }
    }, [taskDetails]);

    useEffect(() => {
        // Retrieve the taskId from sessionStorage
        const taskId = sessionStorage.getItem('taskId');
        console.log('Task ID:', taskId);

        // Fetch details for the taskId from the tasks collection
        const fetchTaskDetails = async () => {
            if (taskId) {
                const taskDocRef = doc(database, 'tasks', taskId);
                try {
                    const taskDocSnap = await getDoc(taskDocRef);
                    if (taskDocSnap.exists()) {

                        const taskData = taskDocSnap.data();
                        setTaskDetails(taskData);

                        // Check if task_worker_id is not null
                        if (taskData.task_worker_id !== null) {
                            // Update state with task_worker_id
                            setIsWorker(taskData.task_worker_id);
                        }

                        // Fetch the list of applicants for the current task from the applications collection
                        const applicationsQuery = query(
                            collection(database, 'applications'),
                            where('task_id', '==', taskId)
                        );

                        const applicationsSnapshot = await getDocs(applicationsQuery);
                        const applicants = applicationsSnapshot.docs.map(doc => ({
                            applicationId: doc.id,
                            ...doc.data()
                        }));

                        console.log('Applicants:', applicants);
                        setTaskApplicants(applicants);
                        // Now you have the list of applicants for the current task
                    } else {
                        console.error('Task not found');
                    }
                } catch (error) {
                    console.error('Error fetching task details:', error);
                }
            }
        };

        fetchTaskDetails();

        // Clear the taskId from sessionStorage (optional)
        // return () => {
        //     sessionStorage.removeItem('taskId');
        // };
    }, []);


    const handleAccepting = (applicantId, applicantName, applicantEmail) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `You are about accept this applicant.`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#F9AA33',
            cancelButtonColor: '#f44336',
            confirmButtonText: 'Yes',
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                handleAccept(applicantId, applicantName, applicantEmail)
            }
        });
    }

    const handleAccept = async (applicantId, applicantName, applicantEmail) => {
        const taskId = sessionStorage.getItem('taskId');

        // Update tasks collection
        const taskDocRef = doc(database, 'tasks', taskId);

        try {
            await updateDoc(taskDocRef, {
                task_worker: applicantName,
                task_worker_id: applicantId,
                task_worker_email: applicantEmail,
                task_status: 'Standby'
            });

            // Update applications collection
            const applicationsQuery = query(
                collection(database, 'applications'),
                where('task_id', '==', taskId)
            );

            const applicationsSnapshot = await getDocs(applicationsQuery);

            const batch = writeBatch(database);

            applicationsSnapshot.forEach((doc) => {
                const data = doc.data();
                const applicationId = doc.id;

                if (data.applicant_id === applicantId) {
                    // Update the accepted applicant
                    batch.update(doc.ref, { is_accepted: true });
                } else {
                    // Reject other applicants
                    batch.update(doc.ref, { is_rejected: true });
                }
            });

            // Commit the batched updates
            await batch.commit();
            // Show alert
            console.log('Successfully Accepted');
            Swal.fire(
                'Success!',
                `Successfully Accepted.`,
                'success'
            );

            // You may want to update your local state here if needed
            setIsWorker(applicantId);
        } catch (error) {
            Swal.fire(
                'Error!',
                `Error accepting applicant.`,
                'error'
            );
            console.error('Error accepting applicant:', error);
        } finally {
            emailjs
                .send(
                    "service_u87csph",
                    "template_95feebc",
                    {
                        user_name: taskDetails.task_author,
                        user_email: taskDetails.task_author_email,
                        message: `Your application has been accepted for the task ${taskDetails.task_title}`,
                        from_name: applicantEmail,
                    },
                    "ML8_-TnOAD7vaYZZq"
                )
                .then(
                    (result) => {
                        console.log(result.text);
                        console.log("message sent");
                        navigate("/errandoffers")
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        }
    };

    const handleMessage = (applicantId) => {

        // Navigate to the Messages component
        navigate(`/messages/${applicantId}`);

        // navigate(`/messages`);
    };
    const handleProfile = (applicantId) => {

        // Navigate to the Messages component
        navigate(`/profilecheck/${applicantId}`);
    };

    const handleMarkDone = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: `You are about mark this as done.`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#F9AA33',
            cancelButtonColor: '#f44336',
            confirmButtonText: 'Yes',
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                Markingdone()
            }
        });
    }
    const Markingdone = async () => {
        const taskId = sessionStorage.getItem('taskId');

        // Update the task status to "Confirming"
        const taskDocRef = doc(database, 'tasks', taskId);

        try {
            await updateDoc(taskDocRef, {
                task_status: 'Completed'
            });

            // Optionally, you may want to update your local state here if needed
            setTaskDetails((prevTask) => ({
                ...prevTask,
                task_status: 'Completed'
            }));
            Swal.fire(
                'Success!',
                `Successfully Updated.`,
                'success'
            );
        } catch (error) {
            console.error('Error marking task as done:', error);
            Swal.fire(
                'Error!',
                `Error marking as done.`,
                'error'
            );
        } finally {
            emailjs
                .send(
                    "service_u87csph",
                    "template_95feebc",
                    {
                        user_name: taskDetails.task_author,
                        user_email: taskDetails.task_author_email,
                        message: `The task status of ${taskDetails.task_title} has been updated to Completed`,
                        from_name: taskDetails.task_worker_email,
                    },
                    "ML8_-TnOAD7vaYZZq"
                )
                .then(
                    (result) => {
                        console.log(result.text);
                        console.log("message sent");
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        }
    };

    const handleAbortTask = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: `You are about abort.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F9AA33',
            cancelButtonColor: '#f44336',
            confirmButtonText: 'Yes',
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                Aborting()
            }
        });
    }
    const Aborting = async () => {
        const taskId = sessionStorage.getItem('taskId');

        // Delete the task from the tasks collection
        const taskDocRef = doc(database, 'tasks', taskId);

        try {
            // Delete the task document
            await deleteDoc(taskDocRef);

            // Optionally, you may want to update your local state here if needed
            setTaskDetails(null);

            // Show alert
            console.log('Successfully deleted');

            // Clear the taskId from sessionStorage
            sessionStorage.removeItem('taskId');

            // Navigate to /myerrandoffers
            navigate('/errandoffers');
            Swal.fire(
                'Info!',
                `Aborted successfully.`,
                'info'
            );
        } catch (error) {
            console.error('Error aborting task:', error);
            Swal.fire(
                'Error!',
                `Error aborting.`,
                'error'
            );
        }
    };

    const handleEdit = (id) => {
        // Store the taskId in sessionStorage
        navigate('/addupdatejob');
        sessionStorage.setItem('taskId', id);
        console.log(id)
    };
    return (
        <>
            <div className="container">
                {taskDetails ? (
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 mt-4">
                            <div className="card">
                                <div class="card-header bg-warning">
                                    <div className="row">
                                        <div className="col-2 col-lg-1 d-flex align-items-center">
                                            <IoChevronBackCircleSharp className='h1 text-light m-0' style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                                        </div>
                                        <div className="col-10 col-lg-11">
                                            <p className="h3 text-light py-2 px-4 m-0">Simple Errand Details</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 p-4 d-flex flex-column">
                                        <p className="text-muted fst-italic m-0">Title:</p>
                                        <p className="h4">{taskDetails.task_title}</p>
                                        <p className="text-muted fst-italic m-0">Description:</p>
                                        <p className="fs-5">{taskDetails.task_description}</p>

                                        {taskDetails.task_status !== 'Completed' && (
                                            <>
                                                {taskDetails.task_status == 'Confirming' && (
                                                    <button className='btn btn-secondary align-self-end btn-lg disabled' onClick={handleMarkDone}>Waiting for Verification</button>
                                                )}

                                                <div className='d-flex flex-fill justify-content-between'>
                                                    {taskDetails.task_status == 'Open' && (
                                                        <>
                                                            <button className='btn btn-danger align-self-end btn-lg' onClick={handleAbortTask}>Abort</button>
                                                            <button className={`btn btn-secondary align-self-end btn-lg ${isWorker ? "d-none" : "d-block"} `} onClick={() => handleEdit(taskDetails.task_id)}>Edit</button>
                                                        </>
                                                    )}
                                                    {taskDetails.task_worker_id && (
                                                        <button className='btn btn-success align-self-end btn-lg' onClick={handleMarkDone}>Mark as done</button>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                        {loading ?
                                            <div className="row container d-flex justify-content-center mt-5">
                                                <div class="loader">
                                                    <div class="loader-text">Loading...</div>
                                                    <div class="loader-bar"></div>
                                                </div>
                                            </div>
                                            :
                                            <>
                                                {taskDetails.task_status === 'Completed' && !hasRated && (
                                                    <div className='row'>
                                                        <div className="col-12">
                                                            <Rating onClick={clickStar} initialValue={rating} />
                                                        </div>
                                                        <div className="col-12">
                                                            <div class="input-group mb-3">
                                                                <div class="form-floating">
                                                                    <input type="text" class="form-control" id="floatingInputGroup1" placeholder="Username" onChange={handleReviewChange} />
                                                                    <label for="floatingInputGroup1">Enter your Review</label>
                                                                </div>
                                                                <button class="btn btn-warning" type="button" id="floatingInputGroup1" onClick={handleRating} >Rate</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {taskDetails.task_status === 'Completed' && hasRated && (
                                                    <>
                                                        <p className='text-muted fst-italic'>Already rated this user.</p>
                                                        <p className="fst-italic btn btn-light" onClick={() => handleProfile(taskDetails.task_worker_id)} style={{ cursor: "pointer" }}>Check User profile to see ratings.</p>
                                                    </>
                                                )}
                                            </>
                                        }
                                    </div>
                                    <div className="col-12 col-lg-6 p-4">
                                        <p className="text-muted fst-italic m-0">Status:</p>
                                        <p className="fs-5">{taskDetails.task_status}</p>
                                        <p className="text-muted fst-italic m-0">Category:</p>
                                        <p className="fs-5">{taskDetails.task_category}</p>
                                        <p className="text-muted fst-italic m-0">Estimated Time of Work:</p>
                                        <p className="fs-5">{taskDetails.task_deadline} {taskDetails.task_hourmin}</p>
                                        <p className="text-muted fst-italic m-0">Budget:</p>
                                        <p className="fs-5">PHP {taskDetails.task_price}</p>
                                        <p className="text-muted fst-italic m-0">Location:</p>
                                        <p className="fs-5">{taskDetails.task_location}</p>
                                        <p className="text-muted fst-italic m-0">Date Posted:</p>
                                        <p className="fs-5">{formatTaskDate(taskDetails.date_created)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-4 col-lg-8">
                            <div className="card text-bg-secondary border-0">
                                <div className="row container">
                                    <div className="col-12 px-4 pt-2">
                                        <p className="fs-5 fst-italic m-0">{isWorker ? "Helpmate:" : "Applicants:"}</p> <hr />
                                    </div>
                                    {isWorker ?
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-8 col-lg-7 px-4 py-0">
                                                    <p className='fs-4 m-0'>{taskDetails.task_worker}</p>
                                                </div>
                                                <div className="col-10 col-lg-5 d-flex justify-content-between py-0">
                                                    <div>
                                                        <button className='btn btn-light' onClick={() => handleProfile(taskDetails.task_worker_id)}>Check Profile</button>
                                                    </div>
                                                    <div>
                                                        <button className='btn btn-warning' onClick={() => handleMessage(taskDetails.task_worker_id)}>Message</button>
                                                    </div>
                                                </div>
                                            </div> <hr />
                                        </div>
                                        :
                                        <>
                                            {taskApplicants.length > 0
                                                ?
                                                taskApplicants.map((item) => {
                                                    return (
                                                        <div className="col-12" key={item.application_id}>
                                                            <div className="row">
                                                                <div className="col-12 col-lg-7 px-4 py-0">
                                                                    <p className='fs-4 m-0'>{item.applicant_name}</p>
                                                                </div>
                                                                <div className="col-10 col-lg-5 d-flex justify-content-between py-0">
                                                                    <div>
                                                                        <button className='btn btn-light' onClick={() => handleProfile(item.applicant_id)}>Check Profile</button>
                                                                    </div>
                                                                    <div>
                                                                        <button className='btn btn-warning' onClick={() => handleAccepting(item.applicant_id, item.applicant_name, item.applicant_email)}>Accept</button>
                                                                    </div>
                                                                </div>
                                                            </div> <hr />
                                                        </div>
                                                    )
                                                })
                                                :
                                                <p className='fs-5 fst-italic ps-4'>No Applicants yet.</p>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="row container d-flex justify-content-center mt-5">
                        <div class="loader">
                            <div class="loader-text">Loading...</div>
                            <div class="loader-bar"></div>
                        </div>
                    </div>

                )}
            </div>
        </>
    )
}

export default ViewJobOffers