import { useContext, createContext, useEffect, useState } from "react";
import {
    GoogleAuthProvider,
    FacebookAuthProvider,
    signInWithRedirect,
    signOut,
    onAuthStateChanged
} from "firebase/auth";
import {
    getDoc,
    setDoc,
    doc,
    collection
} from 'firebase/firestore'

import { auth, database } from '../firebase'

const AuthContext = createContext()

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(true);

    const googleSignIn = () => {
        const provider = new GoogleAuthProvider();
        // signInWithPopup(auth, provider)
        signInWithRedirect(auth, provider)
    };

    const facebookSignIn = () => {
        const provider = new FacebookAuthProvider();
        signInWithRedirect(auth, provider)
    }

    const logout = () => {
        signOut(auth)
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem('username');
        sessionStorage.removeItem('isAccepted');
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            setUser(currentUser);
            console.log('User', currentUser)
            setLoading(false);

            if (currentUser) {
                // Store user.uid in sessionStorage
                sessionStorage.setItem('userId', currentUser.uid);
                sessionStorage.setItem('username', currentUser.displayName);
                // Check if the user exists in Firestore
                const userDocRef = doc(collection(database, 'accounts'), currentUser.uid);
                const userDocSnap = await getDoc(userDocRef);

                // If the user doesn't exist, create a new document in Firestore
                if (!userDocSnap.exists()) {
                    setLoading(true)
                    const userData = {
                        user_id: currentUser.uid,
                        user_bio: "",
                        user_name: currentUser.displayName,
                        user_photo: currentUser.photoURL,
                        user_email: currentUser.email,
                        user_contact_number: currentUser.phoneNumber,
                        user_location: "",
                        user_skills: [],
                        is_verified: false,
                        is_Accepted_TaC: 0,
                        is_ProfileSet: 0,
                    };
                    await setDoc(userDocRef, userData);
                    // setLoading(false);
                    console.log('account successfuly registered')
                    window.location.reload()
                    // window.alert('account successfuly registered')
                }
                // Store user.uid in sessionStorage
                sessionStorage.setItem('userId', currentUser.uid);
                sessionStorage.setItem('username', currentUser.displayName);
            }
        });
        return () => {
            unsubscribe();
        }
    }, [])

    return (
        <AuthContext.Provider value={{ googleSignIn, facebookSignIn, logout, user, loading, setLoading }}>
            {children}
        </AuthContext.Provider>
    )
}

export const UserAuth = () => {
    return useContext(AuthContext)
}