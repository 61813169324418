import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './pages.scss'
import { database } from '../firebase.js';
import { getDoc, doc, collection, query, where, getDocs, Timestamp, orderBy } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { IoMdStar } from "react-icons/io";
import { MdVerified } from "react-icons/md";
import { IoChevronBackCircleSharp } from "react-icons/io5";
import { MdOutlineReportProblem } from "react-icons/md";
import { IoBriefcaseOutline } from "react-icons/io5";
import NoTask from "../assets/NoTask.svg";
import Swal from 'sweetalert2';
import emailjs from "@emailjs/browser";

function ProfileCheck() {
    const navigate = useNavigate();
    const [userData, setUserData] = useState();
    const [userPhoto, setUserPhoto] = useState('');
    const [userBio, setUSerBio] = useState("");
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userNumber, setUserNumber] = useState('');
    const [userLocation, setUserLocation] = useState('');
    const [userSkills, setUserSkills] = useState([]);
    const [userRecentWork, setUserRecentWork] = useState([]);
    const [userIsVerified, setUserIsVerified] = useState('');
    const { userId } = useParams();
    const [averageRating, setAverageRating] = useState(0);
    const [ratingList, setRatingList] = useState([]);
    const [ratingLen, setRatingLen] = useState('');
    const [uploadedIds, setUploadedIds] = useState([]);

    const calculateAverage = (ratings) => {
        setRatingLen(ratings.length)
        if (ratings.length === 0) {
            return 0;
        }
        const totalRating = ratings.reduce((sum, rating) => sum + rating, 0);
        console.log('ave:', totalRating / ratings.length);
        const ave = totalRating / ratings.length;
        setAverageRating(ave.toFixed(1));
    }

    // Function to format the date
    const formatTaskDate = (timestamp) => {
        const currentDate = new Date();
        const taskDate = new Date(timestamp.seconds * 1000);

        // Calculate the time difference in milliseconds
        const timeDifference = currentDate - taskDate;

        // If less than 1 minute ago, display "Just now"
        if (timeDifference < 60 * 1000) {
            return 'Just now';
        } else if (timeDifference < 60 * 60 * 1000) {
            // If less than 1 hour ago, display "X minutes ago"
            const minutesAgo = Math.floor(timeDifference / (60 * 1000));
            return `${minutesAgo} ${minutesAgo === 1 ? 'minute' : 'minutes'} ago`;
        } else if (timeDifference < 24 * 60 * 60 * 1000) {
            // If less than 24 hours ago, display "X hours ago"
            const hoursAgo = Math.floor(timeDifference / (60 * 60 * 1000));
            return `${hoursAgo} ${hoursAgo === 1 ? 'hour' : 'hours'} ago`;
        } else {
            // Otherwise, display the date in the format "Mon. DD, YYYY"
            const options = { month: 'short', day: 'numeric', year: 'numeric' };
            return taskDate.toLocaleDateString('en-US', options);
        }
    };
    // Function to fetch uploaded IDs
    const fetchUploadedIds = async () => {
        const userId = sessionStorage.getItem("userId");
        const userDocRef = doc(collection(database, "accounts"), userId);
        const docSnap = await getDoc(userDocRef);

        if (docSnap.exists()) {
            const ids = docSnap.data().user_ids || [];
            setUploadedIds(ids);
        }
    };

    const handleViewIds = async () => {
        await fetchUploadedIds();
        let idsHtml = '';
    
        if (uploadedIds && uploadedIds.length > 0) {
            idsHtml = uploadedIds.map(id => `
                <div class="id-item">
                    <img src="${id.id_url}" alt="${id.id_type}" class="id-image" />
                    <p>${id.id_type}</p>
                </div>
            `).join('');
        } else {
            idsHtml = '<p>No Uploaded ID yet.</p>';
        }
    
        Swal.fire({
            title: "This User's Uploaded ID's",
            html: idsHtml,
            confirmButtonText: 'Close',
            confirmButtonColor: '#f9aa33',
            width: '80%',
            showConfirmButton: true,
            showCloseButton: true
        });
    };
    

    // Fetch user data from Firestore on component mount
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const userDocRef = doc(collection(database, 'accounts'), userId);
                const userDocSnap = await getDoc(userDocRef);

                if (userDocSnap.exists()) {
                    const userData = userDocSnap.data();
                    setUserData(userData);
                    console.log('user info', userData);

                    // Set other state variables
                    setUserPhoto(userData.user_photo);
                    setUSerBio(userData.user_bio);
                    setUserName(userData.user_name);
                    setUserEmail(userData.user_email);
                    setUserNumber(userData.user_contact_number);
                    setUserLocation(userData.user_location);
                    setUserIsVerified(userData.is_verified);
                    setUserSkills(userData.user_skills);
                    setUserRecentWork(userData.user_recentwork);
                    setUploadedIds(userData.user_ids);
                } else {
                    console.error('User document not found');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, [userId]);

    // Fetch ratings for the user and calculate average
    useEffect(() => {
        const fetchRatings = async () => {
            try {
                const rateeId = userId;
                const ratingQuery = query(collection(database, "ratings"), where("ratee_id", "==", rateeId));
                const ratingDocs = await getDocs(ratingQuery);

                if (!ratingDocs.empty) {
                    const ratingsData = ratingDocs.docs.map((doc) => doc.data());

                    // Sort the ratingsData by date_rated in descending order
                    ratingsData.sort((a, b) => b.date_rated.seconds - a.date_rated.seconds);

                    setRatingList(ratingsData);

                    const avgRating = calculateAverage(
                        ratingsData.map((rating) => rating.rating_value)
                    );
                }
            } catch (error) {
                console.error('Error fetching ratings:', error);
            }
        };

        fetchRatings();
    }, [userId]);

    const handleReport = async (userId, userName, userEmail) => {
        const categories = {
            SPAM: 'Spam',
            HARASSMENT: 'Harassment',
            'INAPPROPRIATE CONTENT': 'Inappropriate Content',
            IMPERSONATION: 'Impersonation',
            'SCAMS AND FRAUD': 'Scams and Fraud',
            'VIOLENCE AND THREATS': 'Violence and Threats',
            'PRIVACY VIOLATIONS': 'Privacy Violations',
            'INTELLECTUAL PROTPERTY VIOLATIONS': 'Intellectual Property Violations',
            'MINSINFORMATION AND FAKE NEWS': 'Misinformation and Fake News',
            'ABUSE OF PLATFORM': 'Abuse of Platform',
            OTHER: 'Other'
        };

        const { value: category } = await Swal.fire({
            title: 'Select a category for you report',
            input: 'select',
            inputOptions: categories,
            inputPlaceholder: 'Select a category',
            showCancelButton: true,
            inputValidator: (value) => {
                return new Promise((resolve) => {
                    if (value) {
                        resolve();
                    } else {
                        resolve('You need to choose a category!');
                    }
                });
            },
            confirmButtonText: 'Next',
            reverseButtons: true,
            confirmButtonColor: '#f9aa33'
        });

        if (category) {
            const { value: text } = await Swal.fire({
                input: 'textarea',
                inputLabel: 'Message',
                confirmButtonText: 'Send Report',
                reverseButtons: true,
                confirmButtonColor: '#f44336',
                inputPlaceholder: 'Type your message here...',
                inputAttributes: {
                    'aria-label': 'Type your message here'
                },
                showCancelButton: true,
                preConfirm: (message) => {
                    if (!message) {
                        Swal.showValidationMessage('You need to type a message!');
                        return false;
                    }
                    return message;
                }
            });

            if (text) {
                Swal.fire({
                    title: 'Are you sure?',
                    text: 'You are about to report this user.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Proceed',
                    cancelButtonText: 'Cancel',
                    reverseButtons: true,
                    confirmButtonColor: '#f44336',
                    cancelButtonColor: '',
                }).then((result) => {
                    if (result.isConfirmed) {
                        ReportUser(userId, userName, userEmail, text, category);
                    }
                });
            }
        }
    }


    const ReportUser = (userId, userName, userEmail, text, category) => {
        emailjs
            .send(
                "service_u87csph",
                "template_4eivrf8",
                {
                    message: `The user ${userName} (${userEmail}) with the user ID: ${userId} has been reported. Report info: "${text}"`,
                    category: category
                },
                "ML8_-TnOAD7vaYZZq"
            )
            .then(
                (result) => {
                    Swal.fire(
                        'Success!',
                        `This user has been reported.`,
                        'success'
                    );
                },
                (error) => {
                    console.log(error);
                }
            );
    }
    const calculateAverageSkills = (skills) => {
        const skillMap = {};

        skills.forEach(skill => {
            for (const [key, value] of Object.entries(skill)) {
                if (skillMap[key]) {
                    skillMap[key].sum += value;
                    skillMap[key].count += 1;
                } else {
                    skillMap[key] = { sum: value, count: 1 };
                }
            }
        });

        const averageSkills = Object.entries(skillMap).map(([key, { sum, count }]) => ({
            skill: key,
            average: sum / count,
        }));

        return averageSkills;
    };

    const averageSkills = calculateAverageSkills(userSkills);

    return (
        <>
            <div className="container">
                <div className="row mt-4">
                    <div className="col-12 d-flex justify-content-between">
                        <IoChevronBackCircleSharp className='h1 text-warning m-0' style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                        <p className="fs-5 text-danger" onClick={() => handleReport(userId, userName, userEmail)} style={{ cursor: "pointer" }}><MdOutlineReportProblem className='h3 text-danger m-0' /> Report</p>

                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="card border-0 shadow p-5 sticky-top">
                            <div className="row">
                                <div className="col-3">
                                    <img src={userPhoto} className="rounded-circle border border-warning border-3" alt="User" style={{ objectFit: "cover", width: "100%" }} />
                                </div>
                                <div className="col-9 d-flex align-items-center">
                                    <div className="row">
                                        <div className="col-12">
                                            <p className="fs-4 m-0">{userName} {userIsVerified && <MdVerified style={{ color: "#F9AA33" }} />}</p>
                                        </div>
                                        <div className="col-12">
                                            <p className="text-muted d-flex align-items-center m-0">{averageRating} <IoMdStar style={{ color: "#F9AA33" }} /> ({ratingLen || "0"} Reviews)</p>
                                        </div>
                                        <div className="col-12">
                                            <p className="text-muted m-0">{userEmail}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12"> <hr />
                                    <div className="row">
                                        <div className="col-12">
                                            <p className="fs-5 text-warning">Personal Information</p>
                                        </div>
                                        <div className="col-12">
                                            <p className="m-0 text-muted">Work Experience:</p>
                                            <div className="container-fluid">
                                                {userRecentWork?.length > 0 ? (
                                                    userRecentWork.map((skills, id) => (
                                                        <p className=" mb-2"><IoBriefcaseOutline className="text-warning" /> {skills}</p>
                                                    ))
                                                ) : (
                                                    <p className="fs-5 text-muted fst-italic">No recent work available.</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <p className="m-0 text-muted">Bio:</p>
                                            <p className="fs-5">{userBio}</p>
                                        </div>
                                        <div className="col-12">
                                            <p className="m-0 text-muted">Address:</p>
                                            <p className="fs-5">{userLocation}</p>
                                        </div>
                                        <div className="col-12">
                                            <p className="m-0 text-muted">Contact Number:</p>
                                            <p className="fs-5">{userNumber}</p>
                                        </div>
                                        <div className="col-12">
                                            <p className="m-0 text-muted mb-2">Skills:</p>
                                            <div className="container-fluid">
                                                {/* {userSkills.length > 0 ? (
                                                    userSkills.map((skills, id) => (
                                                        <span className="d-inline-block text-light text-center bg-warning px-2 py-1 rounded-pill me-2 mb-2">{skills}</span>
                                                    ))
                                                ) : (
                                                    <p className="fs-5 text-muted fst-italic">No skills available.</p>
                                                )} */}
                                                {averageSkills.length > 0 ? (
                                                    averageSkills.map((skill, index) => (
                                                        <div key={index} className="d-inline-block text-light text-center bg-warning px-2 py-1 rounded-pill me-2 mb-2">
                                                            <span>
                                                                {skill.skill} {skill.average.toFixed(1)} <IoMdStar />
                                                            </span>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <p className="fs-5 text-muted fst-italic">No skills available</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button onClick={handleViewIds} className="btn btn-warning">View User ID's</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 p-5">
                        <div className="row">
                            <div className="col-12">
                                <p className='fs-4 text-warning'>Ratings and Reviews</p>
                            </div> <hr />

                            {ratingList && ratingList.length > 0 ? (
                                ratingList.map((rating, index) => (
                                    <div key={index} className='col-12'>
                                        <p className='fs-5 mb-1 d-flex align-items-center'>{rating.rater_name} ({rating.rating_value}<IoMdStar className='pf-rate-star' />)</p>
                                        <span className='text-light text-center bg-warning px-2 py-1 rounded-pill'>{rating.rating_categ}</span>
                                        <p className='fs-5 m-0'>{rating.review}</p>
                                        <p className='text-muted fst-italic'>{formatTaskDate(rating.date_rated)}</p>
                                    </div>
                                ))
                            ) : (
                                <div className='NoTask-container'>
                                    <img src={NoTask} className='NoTask-img' alt='NoTask'></img>
                                    <p>No Ratings and Reviews</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfileCheck