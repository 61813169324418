import React, { useRef, useState, useEffect } from "react";
import "./pages.scss";
import Sidebar from "../components/Sidebar.jsx";
import Header from "../components/Header.jsx";
import SetProfile from "../components/SetProfile.jsx"
import { UserAuth } from "../context/AuthContext.js";
import Swal from 'sweetalert2';
import { auth, database, storage } from "../firebase";
import {
    getDoc,
    doc,
    collection,
    setDoc,
    query,
    where,
    getDocs,
    arrayUnion,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { TiStarFullOutline } from "react-icons/ti";
import { MdVerified } from "react-icons/md";
import { IoMdStar } from "react-icons/io";
import { FaUserEdit } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { IoBriefcaseOutline } from "react-icons/io5";
import NoTask from "../assets/NoTask.svg";

function Profile() {
    const [open, setOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const hiddenFileInput = useRef(null);
    const { user } = UserAuth();
    const [userData, setUserData] = useState();
    const [isEdit, setIsEdit] = useState(true);
    const [isProfileSet, setIsProfileSet] = useState("");
    const [userPhoto, setUserPhoto] = useState("");
    const [userBio, setUSerBio] = useState("");
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userNumber, setUserNumber] = useState("");
    const [userLocation, setUserLocation] = useState("");
    const [locCity, setLocCity] = useState("");
    const [userRecentWork, setUserRecentWork] = useState([]);
    const [userIsVerified, setUserIsVerified] = useState("");
    const [userSkills, setUserSkills] = useState([]);
    const [averageRating, setAverageRating] = useState(0);
    const [ratingList, setRatingList] = useState([]);
    const [ratingLen, setRatingLen] = useState('');
    const [loading, setLoading] = useState(true);
    const [uploadedIds, setUploadedIds] = useState([]);



    const handleOpen = () => {
        setOpen(!open);
    };
    const handleIsEdit = () => {
        setIsEdit(!isEdit);
        console.log(isEdit);
    };
    const handleSaveChanges = async () => {
        setLoading(true);

        setUserRecentWork([...userRecentWork, '']);
        try {
            const userId = sessionStorage.getItem("userId");
            const userDocRef = doc(collection(database, "accounts"), userId);

            // Update user information with new values
            await setDoc(
                userDocRef,
                {
                    user_name: userName,
                    user_email: userEmail,
                    user_bio: userBio,
                    user_contact_number: userNumber,
                    user_location: userLocation,
                    is_ProfileSet: true,
                    user_city: locCity,
                    user_recentwork: userRecentWork //save new array here
                },
                { merge: true }
            );

            console.log("User information updated successfully.");
            setIsEdit(false);
        } catch (error) {
            console.error("Error updating user information:", error);
        } finally {
            setLoading(false);
        }
    };

    // Function to format the date
    const formatTaskDate = (timestamp) => {
        const currentDate = new Date();
        const taskDate = new Date(timestamp.seconds * 1000);

        // Calculate the time difference in milliseconds
        const timeDifference = currentDate - taskDate;

        // If less than 1 minute ago, display "Just now"
        if (timeDifference < 60 * 1000) {
            return 'Just now';
        } else if (timeDifference < 60 * 60 * 1000) {
            // If less than 1 hour ago, display "X minutes ago"
            const minutesAgo = Math.floor(timeDifference / (60 * 1000));
            return `${minutesAgo} ${minutesAgo === 1 ? 'minute' : 'minutes'} ago`;
        } else if (timeDifference < 24 * 60 * 60 * 1000) {
            // If less than 24 hours ago, display "X hours ago"
            const hoursAgo = Math.floor(timeDifference / (60 * 60 * 1000));
            return `${hoursAgo} ${hoursAgo === 1 ? 'hour' : 'hours'} ago`;
        } else {
            // Otherwise, display the date in the format "Mon. DD, YYYY"
            const options = { month: 'short', day: 'numeric', year: 'numeric' };
            return taskDate.toLocaleDateString('en-US', options);
        }
    };

    // Fetch user data from Firestore on component mount
    useEffect(() => {
        const userId = sessionStorage.getItem("userId");

        const fetchUserData = async () => {
            try {
                const userDocRef = doc(collection(database, "accounts"), userId);
                const userDocSnap = await getDoc(userDocRef);

                if (userDocSnap.exists()) {
                    const userData = userDocSnap.data();
                    setUserData(userData);
                    console.log("user info", userData);

                    // Set other state variables
                    setUserPhoto(userData.user_photo);
                    setUSerBio(userData.user_bio);
                    setUserName(userData.user_name);
                    setUserEmail(userData.user_email);
                    setUserNumber(userData.user_contact_number);
                    setUserLocation(userData.user_location);
                    setUserIsVerified(userData.is_verified);
                    setUserSkills(userData.user_skills);
                    setIsProfileSet(userData.is_ProfileSet);
                    setUserRecentWork(userData.user_recentwork);
                    setLocCity(userData.user_city);

                    console.log("User info", userData);
                    console.log(userRecentWork);
                    setLoading(false);
                } else {
                    console.error("User document not found");
                    setLoading(false);
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    // upload button
    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };
    //file upload
    const handleChange = (event) => {
        const fileUploaded = event.target.files[0];
        setSelectedFile(fileUploaded);
    };
    //get fields from parsed text
    const handleSubmitImg = async () => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append("document", selectedFile);

            try {
                const response = await fetch(
                    "https://api.mindee.net/v1/products/mindee/passport/v1/predict",
                    {
                        method: "POST",
                        headers: {
                            Authorization: "Token b06f40903909c03ad0de98d2e7f8d356",
                        },
                        body: formData,
                    }
                );

                if (response.ok) {
                    const responseData = await response.json();

                    // Extract and store given name and surname
                    const givenNames = responseData.document.inference.pages[0].prediction.given_names
                        .map((name) => name.value)
                        .join(" ");
                    const surname =
                        responseData.document.inference.pages[0].prediction.surname.value;
                    const idNumber =
                        responseData.document.inference.pages[0].prediction.id_number.value;
                    const birthPlace =
                        responseData.document.inference.pages[0].prediction.birth_place
                            .value;

                    // Log or store the extracted information
                    console.log("Given Names:", givenNames);
                    console.log("Surname:", surname);
                    console.log("ID Number:", idNumber);
                    console.log("Birth Place:", birthPlace);

                    // Compare userName with givenNames + surname
                    const fullName = `${givenNames} ${surname}`;
                    if (userName === fullName) {
                        // Update is_verified to true in user accounts
                        const userId = sessionStorage.getItem("userId");
                        const userDocRef = doc(collection(database, "accounts"), userId);

                        try {
                            await setDoc(userDocRef, { is_verified: true }, { merge: true });
                            console.log("Verification successful. is_verified set to true.");
                        } catch (error) {
                            console.error("Error updating is_verified:", error);
                        }
                    } else {
                        console.log("concat: ", fullName);
                        // Alert message or handle the case where names do not match
                        alert("Names do not match. Please take another picture.");
                    }
                } else {
                    // Handle error
                    console.error("Error handle:", response.status, response.statusText);
                }
            } catch (error) {
                console.error("Error:", error);
            }
        }
    };

    // Fetch ratings for the user and calculate average
    useEffect(() => {
        const userId = sessionStorage.getItem("userId");
        const fetchRatings = async () => {
            try {
                const rateeId = userId;
                const ratingQuery = query(collection(database, "ratings"), where("ratee_id", "==", rateeId));
                const ratingDocs = await getDocs(ratingQuery);

                if (!ratingDocs.empty) {
                    const ratingsData = ratingDocs.docs.map((doc) => doc.data());

                    // Sort the ratingsData by date_rated in descending order
                    ratingsData.sort((a, b) => b.date_rated.seconds - a.date_rated.seconds);

                    setRatingList(ratingsData);

                    const avgRating = calculateAverage(
                        ratingsData.map((rating) => rating.rating_value)
                    );
                }
            } catch (error) {
                console.error('Error fetching ratings:', error);
            }
        };

        fetchRatings();
    }, []);

    //calcualte average rating
    const calculateAverage = (ratings) => {
        setRatingLen(ratings.length)
        if (ratings.length === 0) {
            return 0;
        }
        const totalRating = ratings.reduce((sum, rating) => sum + rating, 0);
        console.log("ave:", totalRating / ratings.length);
        const ave = totalRating / ratings.length;
        setAverageRating(ave.toFixed(1));
    };

    const calculateAverageSkills = (skills) => {
        const skillMap = {};

        skills.forEach(skill => {
            for (const [key, value] of Object.entries(skill)) {
                if (skillMap[key]) {
                    skillMap[key].sum += value;
                    skillMap[key].count += 1;
                } else {
                    skillMap[key] = { sum: value, count: 1 };
                }
            }
        });

        const averageSkills = Object.entries(skillMap).map(([key, { sum, count }]) => ({
            skill: key,
            average: sum / count,
        }));

        return averageSkills;
    };

    const averageSkills = calculateAverageSkills(userSkills);

    const handleUploadId = async () => {
        const categories = {
            passport: 'Passport',
            'drivers-license': "Driver's License",
            'national-id': 'National ID',
            'social-security': 'Social Security',
            'phil-id': 'Philippine Identification System ID (PhilID)',
            'umid': 'Unified Multi-Purpose ID (UMID)',
            'gsis-ecard': 'Government Service Insurance System (GSIS) eCard',
            'pag-ibig': 'Pag-IBIG Fund Loyalty Card',
            'philhealth': 'PhilHealth ID'
        };

        const { value: idType } = await Swal.fire({
            title: 'Select ID Type',
            input: 'select',
            inputOptions: categories,
            inputPlaceholder: 'Select an ID Type',
            showCancelButton: true,
            inputValidator: (value) => {
                return new Promise((resolve) => {
                    if (value) {
                        resolve();
                    } else {
                        resolve('You need to choose an ID type!');
                    }
                });
            },
            confirmButtonText: 'Next',
            confirmButtonColor: '#f9aa33',
            reverseButtons: true,
        });

        if (idType) {
            const { value: file } = await Swal.fire({
                title: 'Select your ID image',
                input: 'file',
                inputAttributes: {
                    'accept': 'image/*',
                    'aria-label': 'Upload your ID image'
                },
                showCancelButton: true,
                confirmButtonText: 'Upload',
                confirmButtonColor: '#f9aa33',
                reverseButtons: true,
            });

            if (file) {
                const userId = sessionStorage.getItem("userId");
                const storageRef = ref(storage, `${userId}/${new Date().getTime()}_${file.name}`);
                const uploadTask = uploadBytesResumable(storageRef, file);

                uploadTask.on('state_changed',
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log(`Upload is ${progress}% done`);
                    },
                    (error) => {
                        console.error('Upload failed:', error);
                        Swal.fire('Error', 'File upload failed', 'error');
                    },
                    async () => {
                        const fileURL = await getDownloadURL(uploadTask.snapshot.ref);
                        const userId = sessionStorage.getItem("userId");
                        const userDocRef = doc(collection(database, "accounts"), userId);
                        const idData = {
                            id_type: idType,
                            id_url: fileURL,
                            uploaded_at: new Date()
                        };

                        try {
                            // Add idData to user_ids array in the accounts collection
                            await setDoc(userDocRef, { user_ids: arrayUnion(idData) }, { merge: true });

                            // Show success message
                            Swal.fire({
                                title: 'ID Uploaded',
                                text: `Your ${idType} has been uploaded successfully.`,
                                imageUrl: fileURL,
                                imageAlt: 'Uploaded ID',
                                confirmButtonText: 'Close',
                                confirmButtonColor: '#f9aa33'
                            });

                            // Fetch and display uploaded IDs
                            fetchUploadedIds();
                        } catch (error) {
                            console.error('Error saving ID data:', error);
                            Swal.fire('Error', 'Failed to save ID data', 'error');
                        }
                    }
                );
            }
        }
    };

    // Function to fetch uploaded IDs
    const fetchUploadedIds = async () => {
        const userId = sessionStorage.getItem("userId");
        const userDocRef = doc(collection(database, "accounts"), userId);
        const docSnap = await getDoc(userDocRef);

        if (docSnap.exists()) {
            const ids = docSnap.data().user_ids || [];
            setUploadedIds(ids);
        }
    };

    const handleViewIds = async () => {
        await fetchUploadedIds();
        let idsHtml = '';

        if (uploadedIds && uploadedIds.length > 0) {
            idsHtml = uploadedIds.map(id => `
                <div class="id-item">
                    <img src="${id.id_url}" alt="${id.id_type}" class="id-image" />
                    <p>${id.id_type}</p>
                </div>
            `).join('');
        } else {
            idsHtml = '<p>No Uploaded ID yet.</p>';
        }

        Swal.fire({
            title: "Your Uploaded ID's",
            html: idsHtml,
            confirmButtonText: 'Close',
            confirmButtonColor: '#f9aa33',
            width: '80%',
            showConfirmButton: true,
            showCloseButton: true
        });
    };


    return (
        <>
            {
                loading ? (
                    <div className="container d-flex justify-content-center mt-5">
                        <div className="loader">
                            <div className="loader-text">Loading...</div>
                            <div className="loader-bar"></div>
                        </div>
                    </div>
                ) :
                    <>
                        {
                            isProfileSet ?
                                <>
                                    {/* <div className="btn btn-warning" onClick={handleSaveChanges}>click</div> */}
                                    <div className="container">
                                        <div className="row mt-4">
                                            <div className="col-12 col-lg-6">
                                                <div className="card border-0 shadow p-5 sticky-top">
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <img src={userPhoto} className="rounded-circle border border-warning border-3" alt="User" style={{ objectFit: "cover", width: "100%" }} />
                                                        </div>
                                                        <div className="col-9 d-flex align-items-center">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <p className="fs-4 m-0">{userName} {userIsVerified && <MdVerified style={{ color: "#F9AA33" }} />}</p>
                                                                </div>
                                                                <div className="col-12">
                                                                    <p className="text-muted d-flex align-items-center m-0">{averageRating} <IoMdStar style={{ color: "#F9AA33" }} /> ({ratingLen || "0"} Reviews)</p>
                                                                </div>
                                                                <div className="col-12">
                                                                    <p className="text-muted m-0">{userEmail}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12"> <hr />
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <p className="fs-5 text-warning">Personal Information</p>
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <div className="text-warning border-0 d-flex align-items center fs-3" style={{ cursor: "pointer" }} onClick={() => setIsProfileSet(0)}><FaEdit /></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <p className="m-0 text-muted">Work Experience:</p>
                                                                    <div className="container-fluid">
                                                                        {userRecentWork?.length > 0 ? (
                                                                            userRecentWork.map((skills, id) => (
                                                                                <p className=" mb-2"><IoBriefcaseOutline className="text-warning" /> {skills}</p>
                                                                            ))
                                                                        ) : (
                                                                            <p className="fs-5 text-muted fst-italic">No recent work available.</p>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <p className="m-0 text-muted">Bio:</p>
                                                                    <p className="fs-5">{userBio}</p>
                                                                </div>
                                                                <div className="col-12">
                                                                    <p className="m-0 text-muted">Address:</p>
                                                                    <p className="fs-5">{userLocation}</p>
                                                                </div>
                                                                <div className="col-12">
                                                                    <p className="m-0 text-muted">Contact Number:</p>
                                                                    <p className="fs-5">{userNumber}</p>
                                                                </div>
                                                                <div className="col-12">
                                                                    <p className="m-0 text-muted mb-2 ">Skills:</p>
                                                                    <div className="container-fluid">
                                                                        {/* {userSkills.length > 0 ? (
                                                                            userSkills.map((skills, id) => (
                                                                                <span className="d-inline-block text-light text-center bg-warning px-2 py-1 rounded-pill me-2 mb-2">{skills}</span>
                                                                            ))
                                                                        ) : (
                                                                            <p className="fs-5 text-muted fst-italic">No skills available.</p>
                                                                        )} */}
                                                                        {averageSkills.length > 0 ? (
                                                                            averageSkills.map((skill, index) => (
                                                                                <div key={index} className="d-inline-block text-light text-center bg-warning px-2 py-1 rounded-pill me-2 mb-2">
                                                                                    <span>
                                                                                        {skill.skill} {skill.average.toFixed(1)} <IoMdStar />
                                                                                    </span>
                                                                                </div>
                                                                            ))
                                                                        ) : (
                                                                            <p className="fs-5 text-muted fst-italic">No skills available</p>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 d-flex">
                                                                    <button onClick={handleViewIds} className="btn btn-warning">View ID</button>
                                                                    <button onClick={handleUploadId} className="btn btn-warning ms-auto">Upload ID</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6 p-5">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <p className='fs-4 text-warning'>Ratings and Reviews</p>
                                                    </div> <hr />

                                                    {ratingList && ratingList.length > 0 ? (
                                                        ratingList.map((rating, index) => (
                                                            <div key={index} className='col-12'>
                                                                <p className='fs-5 mb-1 d-flex align-items-center'>{rating.rater_name} ({rating.rating_value}<IoMdStar className='pf-rate-star' />)</p>
                                                                <span className='text-light text-center bg-warning px-2 py-1 rounded-pill'>{rating.rating_categ}</span>
                                                                <p className='fs-5 m-0'>{rating.review}</p>
                                                                <p className='text-muted fst-italic'>{formatTaskDate(rating.date_rated)}</p>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div className='NoTask-container'>
                                                            <img src={NoTask} className='NoTask-img' alt='NoTask'></img>
                                                            <p>No Ratings and Reviews</p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <SetProfile setIsEdit={setIsEdit} loading={loading} userData={userData} setUserName={setUserName} setUSerBio={setUSerBio} setUserNumber={setUserNumber} setUserLocation={setUserLocation} setLocCity={setLocCity} setUserRecentWork={setUserRecentWork} setIsProfileSet={setIsProfileSet} userPhoto={userPhoto} userBio={userBio} userName={userName} userEmail={userEmail} userNumber={userNumber} userLocation={userLocation} userRecentWork={userRecentWork} handleSaveChanges={handleSaveChanges} />
                                </>
                        }
                    </>
            }
        </>

    );
}

export default Profile;
