import React, { useState, useEffect, useRef } from 'react';
import './pages.scss';
import Sidebar from '../components/Sidebar.jsx';
import Header from '../components/Header.jsx';
import { useNavigate } from "react-router";
import { useParams } from 'react-router-dom';
import { auth, database, storage } from "../firebase";
import {
  addDoc,
  collection,
  onSnapshot,
  orderBy,
  query,
  doc,
  getDoc,
} from "firebase/firestore";
import { FaUser } from "react-icons/fa";
import { IoSend } from "react-icons/io5";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { RiImageAddFill } from "react-icons/ri";
import { IoLocationSharp, IoChevronBackCircleSharp } from "react-icons/io5";

function Messages() {
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [receiverData, setReceiverData] = useState(null);
  const [chatMessage, setChatMessage] = useState("");
  const [allMessages, setAllMessages] = useState([]);
  const [chatImage, setChatImage] = useState(null);
  const user = auth.currentUser;
  const navigate = useNavigate();
  const { applicantId } = useParams();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [visibleTimestamps, setVisibleTimestamps] = useState("");
  const convoBodyRef = useRef(null);

  // Function to toggle the visibility of a timestamp
  const toggleTimestampVisibility = (id) => {
    setVisibleTimestamps((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  // Function to format the date
  const formatTaskDate = (timestamp) => {
    const currentDate = new Date();
    const taskDate = new Date(timestamp.seconds * 1000);

    // Calculate the time difference in milliseconds
    const timeDifference = currentDate - taskDate;

    // If less than 1 minute ago, display "Just now"
    if (timeDifference < 60 * 1000) {
      return 'Just now';
    } else if (timeDifference < 60 * 60 * 1000) {
      // If less than 1 hour ago, display "X minutes ago"
      const minutesAgo = Math.floor(timeDifference / (60 * 1000));
      return `${minutesAgo} ${minutesAgo === 1 ? 'minute' : 'minutes'} ago`;
    } else if (timeDifference < 24 * 60 * 60 * 1000) {
      // If less than 24 hours ago, display "X hours ago"
      const hoursAgo = Math.floor(timeDifference / (60 * 60 * 1000));
      return `${hoursAgo} ${hoursAgo === 1 ? 'hour' : 'hours'} ago`;
    } else {
      // Otherwise, display the date in the format "Mon. DD, YYYY"
      const options = { month: 'short', day: 'numeric', year: 'numeric' };
      return taskDate.toLocaleDateString('en-US', options);
    }
  };
  //Sending message
  const sendMessage = async () => {
    setIsDisabled(true)
    try {
      if (user && receiverData && (chatMessage.trim() !== "" || chatImage)) {
        const messageData = {
          username: user.displayName,
          messageUserId: user.uid,
          message: chatMessage,
          timestamp: new Date(),
        };
        if (chatImage) {
          const imageRef = ref(storage, `chatImages/${new Date().getTime()}_${chatImage.name}`);
          const snapshot = await uploadBytes(imageRef, chatImage);
          const imageUrl = await getDownloadURL(snapshot.ref);
          messageData.imageUrl = imageUrl;
        }
        await addDoc(collection(database, "accounts", user.uid, "chatUsers", receiverData.userId, "messages"), messageData);
        await addDoc(collection(database, "accounts", receiverData.userId, "chatUsers", user.uid, "messages"), messageData);
      }

      console.log(users)
    } catch (error) {
      console.error(error);
    }
    setChatMessage("");
    setChatImage(null);
    setSelectedFile(null)
    setIsDisabled(false);
  };

  //get all users
  useEffect(() => {
    const unsub = onSnapshot(collection(database, "accounts"), (snapshot) => {
      setUsers(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    }, (error) => {
      console.error("Error fetching users:", error);
    });

    // console.log("users:", users)
    return unsub;
  }, []);

  //fetch messages
  useEffect(() => {
    if (receiverData) {
      const unsub = onSnapshot(
        query(
          collection(
            database,
            "accounts", // Collection ID set to "accounts"
            user?.uid,
            "chatUsers",
            receiverData?.userId,
            "messages"
          ),
          orderBy("timestamp")
        ),
        (snapshot) => {
          setAllMessages(
            snapshot.docs.map((doc) => ({
              id: doc.id,
              messages: doc.data(),
            }))
          );
        }
      );
      return unsub;
    }
  }, [receiverData?.userId]);

  //fetch user from params
  useEffect(() => {
    console.log("1", applicantId)
    const fetchUserData = async () => {
      console.log("2")
      if (applicantId) {
        console.log("3")
        const userDocRef = doc(collection(database, 'accounts'), applicantId);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          console.log("User data:", userData);
          setReceiverData({ username: userData.user_name, userId: userData.user_id, })
        } else {
          console.log("No user data found for user_id:", applicantId);
        }
        console.log("4")
      }
    };
    console.log("5")

    fetchUserData();
    console.log("6")
  }, [applicantId]);

  useEffect(() => {
    const convoBody = document.getElementById('convo-body');

    if (convoBody) {
      convoBody.scrollTop = convoBody.scrollHeight;
    }
  }, [receiverData?.userId, allMessages]);


  const handleCheckProfile = () => {
    // Navigate to the Profile page with user details as URL parameters
    navigate(`/profilecheck/${receiverData.userId}`);
  };

  const handleFileSelect = () => {
    document.getElementById('fileInput').click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setChatImage(event.target.files[0]);
    setSelectedFile(file ? file.name : null);
  };

  return (
    <>
      <div className="container-fluid">
        {receiverData ?
          <>
            <div className="row fixed-top p-2 bg-white shadow">
              <div className="col-1">
                <IoChevronBackCircleSharp className='h1 text-secondary m-0' style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
              </div>
              <div className="col-11 d-flex justify-content-between">
                <span className='fs-4 text-muted'>{receiverData.username}</span>
                <button onClick={handleCheckProfile} className='btn btn-secondary'>Check Profile</button>
              </div>
            </div>
            <div className="row mt-5 d-flex flex-column " id="convo-body" ref={convoBodyRef}>
              {allMessages ?
                <>
                  {allMessages &&
                    allMessages.map(({ id, messages }, index) => {
                      // Check if it's the first message or if the sender is different from the previous message
                      const isFirstMessage = index === 0 || messages.messageUserId !== allMessages[index - 1]?.messages.messageUserId;
                      return (
                        <div className='message-container'>
                          <div className="col-12">
                            {isFirstMessage && <span className={`d-block ${user?.uid === messages.messageUserId ? 'text-end' : 'text-start'} py-2 px-3 minContent`}>{user?.uid === messages.messageUserId ? "Me" : messages.username}</span>}
                          </div>
                          <div className="col-12 d-flex">
                            <div className={`bubble w-50 ${user?.uid === messages.messageUserId ? "ms-auto" : "me-auto"} d-flex `}>
                              <div className={`msg d-flex ${user?.uid === messages.messageUserId ? "ms-auto" : ""} `}>
                                <span className={`text-break ${user?.uid === messages.messageUserId ? "bg-warning text-end" : "bg-secondary text-start"} py-2 px-3 rounded-4 mb-1`}>{messages.message}</span>
                              </div>
                            </div>
                          </div>
                          <div className={`col-5 ${user?.uid === messages.messageUserId ? 'ms-auto' : 'me-auto'}`}>
                            <div className={`d-flex mb-2`}>
                              {messages.imageUrl && <img src={messages.imageUrl} alt="Sent image" className={`p-2 rounded-4 ${user?.uid === messages.messageUserId ? 'ms-auto bg-warning' : 'me-auto bg-secondary'}`} style={{ maxWidth: '400px', maxHeight: '200px', objectFit: 'contain' }} />}
                            </div>
                          </div>
                          {visibleTimestamps[id] && (
                            <span
                              className={`text-muted fst-italic smooth ${user?.uid === messages.messageUserId ? 'text-end' : 'text-start'}`}
                              style={{ fontSize: "13px" }}
                            >
                              {formatTaskDate(messages.timestamp)}
                            </span>
                          )}
                        </div>
                      )
                    })}
                </>
                :
                <div className="loader">
                  <div className="loader-text">Loading...</div>
                  <div className="loader-bar"></div>
                </div>}

              {isDisabled ?
                <span className='fs-6 text-muted text-end fst-italic smooth'>Sending...</span> : null
              }
            </div>
            <div className="row bg-secondary p-2 sticky-bottom">
              {selectedFile ?
                <span className='text-white m-1'>{selectedFile}</span>
                :
                null}
              <div class={`input-group ${isDisabled ? "disabled" : null}`}>
                <input value={chatMessage} onChange={(e) => setChatMessage(e.target.value)} type="text" class="form-control" disabled={isDisabled} placeholder="Enter Message" aria-label="Recipient's username with two button addons" onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    sendMessage();
                  }
                }} />
                <input type="file" id="fileInput" accept="image/*" onChange={handleFileChange} style={{ display: 'none' }} />
                <button class={`btn btn-secondary ${isDisabled ? "disabled" : null}`} type="button" onClick={handleFileSelect} style={{ cursor: "pointer" }}><RiImageAddFill className='h4 m-0' /></button>
                {chatMessage == "" ?
                  <button class={`btn btn-secondary disabled`} type="button" onClick={() => sendMessage()} style={{ cursor: "pointer" }}><IoSend className='h4 m-0' /></button>
                  :
                  <button class={`btn btn-secondary ${isDisabled ? "disabled" : null}`} type="button" onClick={() => sendMessage()} style={{ cursor: "pointer" }}><IoSend className='h4 m-0' /></button>

                }
              </div>
            </div>
          </>
          :
          <div className="container d-flex justify-content-center mt-5">
            <div className="loader">
              <div className="loader-text">Loading...</div>
              <div className="loader-bar"></div>
            </div>
          </div>
        }
      </div>
    </>
  );
}

export default Messages;