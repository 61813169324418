// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAuovcn3DXy6IMT1-0cIyk_GiUmmkhBl0g",
  authDomain: "pahelp-a0c98.firebaseapp.com",
  projectId: "pahelp-a0c98",
  storageBucket: "pahelp-a0c98.appspot.com",
  messagingSenderId: "394736852372",
  appId: "1:394736852372:web:aa96238b30fe6249eac381"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const database = getFirestore(app);
export const storage = getStorage(app);
