import React, { useEffect, useState } from "react";
import "./pages.scss";
import Sidebar from "../components/Sidebar.jsx";
import Header from "../components/Header.jsx";
import { useNavigate, Link } from "react-router-dom";
import {
  collection,
  addDoc,
  Timestamp,
  doc,
  getDoc,
  updateDoc,
  query,
  where,
  getDocs,
  setDoc,
  arrayUnion,
} from "firebase/firestore";
import { database } from "../firebase.js";
import { IoLocationSharp, IoChevronBackCircleSharp } from "react-icons/io5";
import { Rating } from "react-simple-star-rating";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";

function ViewBrowseJobs() {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const [taskDetails, setTaskDetails] = useState(null);
  const [isApplied, setIsApplied] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [hasRated, setHasRated] = useState(false);
  const [loading, setLoading] = useState(false);

  // Catch Rating value
  const handleRating = (rate) => {
    if (review == "") {
      Swal.fire("Warning!", `Please enter your review.`, "warning");
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: `You are about rate this user.`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#F9AA33",
        cancelButtonColor: "#f44336",
        confirmButtonText: "Yes",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          handleRate(rate);
        }
      });
    }
    console.log("rate: ", rate);
  };

  const handleRate = async () => {
    try {
      const raterName = sessionStorage.getItem("username");
      const rateeId = taskDetails.task_author_id;
      const dateRated = Timestamp.now();
      const ratingCateg = "as Helpseeker";
      const ratingTaskId = taskDetails.task_id;

      // Create a new rating document in Firebase
      const ratingDocRef = await setDoc(doc(collection(database, "ratings")), {
        rater_name: raterName,
        ratee_id: rateeId,
        rating_value: rating,
        review: review,
        rating_categ: ratingCateg,
        date_rated: dateRated,
        rating_task_id: ratingTaskId,
      });

      AddskillonRate(rateeId, ratingCateg, rating);
      setRating(0);
      setReview("");
      Swal.fire("Success!", `Successfully Rated.`, "success");
    } catch (error) {
      Swal.fire("Error!", `Error submitting rating.`, "error");
      console.error("Error submitting rating:", error);
    } finally {
      navigate("/appliederrands");
    }
  };

  const clickStar = (rate) => {
    setRating(rate);
    console.log(rate);
  };

  const AddskillonRate = async (id, ratingCateg, ratingVal) => {
    const userDocRef = doc(database, "accounts", id);

    try {
      // Fetch the current document data
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();

        // Check if user_skills exists, if not initialize it as an empty array
        let updatedSkills = userData.user_skills ? [...userData.user_skills] : [];

        // Create a new skill object
        const newSkill = { [ratingCateg]: ratingVal };

        // Add the new skill to the user_skills array
        console.log('Updated skills before adding:', updatedSkills);
        updatedSkills.push(newSkill);
        console.log('Updated skills after adding:', updatedSkills);


        // Update the user document with the new user_skills array
        await updateDoc(userDocRef, {
          user_skills: updatedSkills
        });

        console.log("User skills updated successfully.");
      } else {
        console.log("User document not found.");
      }
    } catch (error) {
      console.error("Error updating user skills:", error);
    }
  };

  const handleReviewChange = (e) => {
    setReview(e.target.value);
  };

  // Function to format the date
  const formatTaskDate = (timestamp) => {
    const currentDate = new Date();
    const taskDate = new Date(timestamp.seconds * 1000);

    // Calculate the time difference in milliseconds
    const timeDifference = currentDate - taskDate;

    // If less than 1 minute ago, display "Just now"
    if (timeDifference < 60 * 1000) {
      return "Just now";
    } else if (timeDifference < 60 * 60 * 1000) {
      // If less than 1 hour ago, display "X minutes ago"
      const minutesAgo = Math.floor(timeDifference / (60 * 1000));
      return `${minutesAgo} ${minutesAgo === 1 ? "minute" : "minutes"} ago`;
    } else if (timeDifference < 24 * 60 * 60 * 1000) {
      // If less than 24 hours ago, display "X hours ago"
      const hoursAgo = Math.floor(timeDifference / (60 * 60 * 1000));
      return `${hoursAgo} ${hoursAgo === 1 ? "hour" : "hours"} ago`;
    } else {
      // Otherwise, display the date in the format "Mon. DD, YYYY"
      const options = { month: "short", day: "numeric", year: "numeric" };
      return taskDate.toLocaleDateString("en-US", options);
    }
  };
  // Fetch user info
  useEffect(() => {
    const userId = sessionStorage.getItem("userId");

    const fetchUserInfo = async () => {
      if (userId) {
        const userDocRef = doc(collection(database, "accounts"), userId);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setUserInfo(userDocSnap.data());
          console.log(userInfo);
        }
      }
    };

    fetchUserInfo();
  }, []);

  //get if already rated
  useEffect(() => {
    const checkRating = async () => {
      setLoading(true);
      try {
        const raterName = sessionStorage.getItem("username");
        const ratingTaskId = taskDetails.task_id;

        // Query the ratings collection to check if the user has already rated the task
        const ratingsQuery = query(
          collection(database, "ratings"),
          where("rater_name", "==", raterName),
          where("rating_task_id", "==", ratingTaskId)
        );

        const ratingsSnapshot = await getDocs(ratingsQuery);

        // If there is at least one rating document, the user has rated the task
        setHasRated(!ratingsSnapshot.empty);
      } catch (error) {
        console.error("Error checking rating:", error);
      } finally {
        setLoading(false);
      }
    };

    // Check the rating when the component mounts
    if (taskDetails) {
      checkRating();
    }
  }, [taskDetails]);

  //Get task info
  useEffect(() => {
    // Retrieve the taskId from sessionStorage
    const userId = sessionStorage.getItem("userId");
    const taskId = sessionStorage.getItem("taskId");
    console.log("Task ID:", taskId);

    // Fetch details for the taskId from the tasks collection
    const fetchTaskDetails = async () => {
      if (taskId) {
        const taskDocRef = doc(database, "tasks", taskId);
        const applicationQuery = query(
          collection(database, "applications"),
          where("applicant_id", "==", userId),
          where("task_id", "==", taskId)
        );

        try {
          const [taskDocSnap, applicationQuerySnap] = await Promise.all([
            getDoc(taskDocRef),
            getDocs(applicationQuery),
          ]);
          if (taskDocSnap.exists()) {
            setTaskDetails(taskDocSnap.data());

            // Check if the user has already applied for the task
            if (!applicationQuerySnap.empty) {
              // Get application info for this task
              const applicationData = applicationQuerySnap.docs[0].data();
              console.log("Application Data:", applicationData);

              if (applicationData.is_accepted) {
                setIsAccepted(true);
              } else if (applicationData.is_rejected) {
                setIsRejected(true);
              } else {
                setIsApplied(true);
              }
            }
          } else {
            console.error("Task not found");
          }
        } catch (error) {
          console.error("Error fetching task details:", error);
        }
      }
    };

    fetchTaskDetails();

    // return () => {
    //   sessionStorage.removeItem("taskId");
    // };
  }, []);

  // Handle add/update task
  const handleApplyTask = async () => {
    // Retrieve the data from sessionStorage
    const userId = sessionStorage.getItem("userId");
    const username = sessionStorage.getItem("username");
    const taskId = sessionStorage.getItem("taskId");

    // Create a new task document in Firestore or update existing task
    const applyData = {
      applicant_id: userId,
      applicant_name: username,
      applicant_email: userInfo.user_email,
      task_id: taskId,
      date_applied: Timestamp.now(),
      is_accepted: false,
      is_rejected: false,
    };

    try {
      const docRef = await addDoc(
        collection(database, "applications"),
        applyData
      );
      // Update the application_id field with the generated document ID
      await updateDoc(docRef, { application_id: docRef.id });
      // navigate('/appliedjobs');
      console.log("apllication sent");
      setIsApplied(true);
    } catch (error) {
      console.error("Error applying for task: ", error);
    }
  };

  const handleProfile = (authorId) => {
    // Navigate to the Messages component
    navigate(`/profilecheck/${authorId}`);
  };

  const handleMessage = (applicantId) => {
    // Navigate to the Messages component
    navigate(`/messages/${applicantId}`);

    // navigate(`/messages`);
  };

  const handleMarkDone = async () => {
    //send email
    const taskId = sessionStorage.getItem("taskId");

    // Update the task status to "Confirming"
    const taskDocRef = doc(database, "tasks", taskId);

    try {
      await updateDoc(taskDocRef, {
        task_status: "Confirming",
      });

      // Optionally, you may want to update your local state here if needed
      setTaskDetails((prevTask) => ({
        ...prevTask,
        task_status: "Confirming",
      }));
    } catch (error) {
      console.error("Error marking task as done:", error);
    } finally {
      emailjs
        .send(
          "service_u87csph",
          "template_95feebc",
          {
            user_name: taskDetails.task_worker,
            user_email: taskDetails.task_worker_email,
            message: `Your hired Helpmate ${taskDetails.task_worker} has finished doing the errand. The task status of ${taskDetails.task_title} has been updated to Confirming. Please verify the finished errand.`,
            from_name: taskDetails.task_author_email,
          },
          "ML8_-TnOAD7vaYZZq"
        )
        .then(
          (result) => {
            console.log(result.text);
            console.log("message sent");
          },
          (error) => {
            console.log(error);
          }
        );
    }
  };

  const handleStart = async () => {
    //send email
    const taskId = sessionStorage.getItem("taskId");

    // Update the task status to "Confirming"
    const taskDocRef = doc(database, "tasks", taskId);

    try {
      await updateDoc(taskDocRef, {
        task_status: "In Progress",
      });

      // Optionally, you may want to update your local state here if needed
      setTaskDetails((prevTask) => ({
        ...prevTask,
        task_status: "In Progress",
      }));
    } catch (error) {
      console.error("Error marking task as done:", error);
    } finally {
      emailjs
        .send(
          "service_u87csph",
          "template_95feebc",
          {
            user_name: taskDetails.task_worker,
            user_email: taskDetails.task_worker_email,
            message: `Your hired Helpmate ${taskDetails.task_worker} has started doing the errand. The task status of ${taskDetails.task_title} has been updated to In Progress.`,
            from_name: taskDetails.task_author_email,
          },
          "ML8_-TnOAD7vaYZZq"
        )
        .then(
          (result) => {
            console.log(result.text);
            console.log("message sent");
          },
          (error) => {
            console.log(error);
          }
        );
    }
  };

  return (
    <>
      <div className="container">
        {taskDetails ? (
          <div className="row d-flex justify-content-center">
            <div className="col-12 mt-4">
              <div className="card">
                <div class="card-header bg-warning">
                  <div className="row">
                    <div className="col-2 col-lg-1 d-flex align-items-center">
                      <IoChevronBackCircleSharp
                        className="h1 text-light m-0"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate(-1)}
                      />
                    </div>
                    <div className="col-10 col-lg-11">
                      <p className="h3 text-light py-2 px-4 m-0">
                        Simple Errand Details
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row p-2">
                  <div className="col-12 col-lg-6 p-4 d-flex flex-column">
                    <p className="text-muted fst-italic m-0">Title:</p>
                    <p className="h4">{taskDetails.task_title}</p>
                    <p className="text-muted fst-italic m-0">Description:</p>
                    <p className="fs-5">{taskDetails.task_description}</p>
                    <div className="row mb-4">
                      <div className="col-12 col-lg-6">
                        <p className="text-muted fst-italic m-0">
                          Help Seeker:
                        </p>
                        <p className="fs-5">{taskDetails.task_author} </p>
                      </div>
                      <div className="col-12 col-lg-6 d-flex justify-content-between align-items-center">
                        <>
                          <button
                            className="btn btn-secondary btn-sm"
                            onClick={() =>
                              handleProfile(taskDetails.task_author_id)
                            }
                          >
                            Check Profile
                          </button>
                        </>
                        <>
                          <button
                            className="btn btn-warning btn-sm"
                            onClick={() =>
                              handleMessage(taskDetails.task_author_id)
                            }
                          >
                            Message
                          </button>
                        </>
                      </div>
                    </div>

                    {taskDetails.task_status !== "Completed" && (
                      <>
                        <div className="d-flex flex-fill justify-content-between">
                          {taskDetails.task_status == "Confirming" && (
                            <button className="btn btn-secondary align-self-end btn-lg disabled">
                              Waiting for verification...
                            </button>
                          )}
                          {taskDetails.task_status == "In Progress" && (
                            //set status as Confirming
                            <button
                              className="btn btn-success align-self-end btn-lg"
                              onClick={handleMarkDone}
                            >
                              Mark as done
                            </button>
                          )}
                          {isAccepted &&
                            taskDetails.task_status == "Standby" ? (
                            //set status as In Progress
                            <button
                              className="btn btn-success align-self-end btn-lg"
                              onClick={handleStart}
                            >
                              Start errand
                            </button>
                          ) : null}

                          {isRejected ? (
                            <button className="btn btn-danger align-self-end btn-lg disabled">
                              Your application has been rejected
                            </button>
                          ) : (
                            <>
                              {isAccepted ? null : (
                                <>
                                  {isApplied ? (
                                    <button className="btn btn-secondary align-self-end btn-lg disabled">
                                      Application Sent
                                    </button>
                                  ) : (
                                    <>
                                      <button
                                        className="btn btn-warning align-self-end btn-lg"
                                        onClick={handleApplyTask}
                                      >
                                        Apply
                                      </button>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </>
                    )}
                    {loading ? (
                      <div className="row container d-flex justify-content-center mt-5">
                        <div class="loader">
                          <div class="loader-text">Loading...</div>
                          <div class="loader-bar"></div>
                        </div>
                      </div>
                    ) : (
                      <>
                        {taskDetails.task_status === "Completed" &&
                          !hasRated && (
                            <div className="row">
                              <div className="col-12">
                                <Rating
                                  onClick={clickStar}
                                  initialValue={rating}
                                />
                              </div>
                              <div className="col-12">
                                <div class="input-group mb-3">
                                  <div class="form-floating">
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="floatingInputGroup1"
                                      placeholder="Username"
                                      onChange={handleReviewChange}
                                    />
                                    <label for="floatingInputGroup1">
                                      Enter your Review
                                    </label>
                                  </div>
                                  <button
                                    class="btn btn-warning"
                                    type="button"
                                    id="floatingInputGroup1"
                                    onClick={handleRating}
                                  >
                                    Rate
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        {taskDetails.task_status === "Completed" &&
                          hasRated && (
                            <>
                              <p className="text-muted fst-italic">
                                Already rated this user.
                              </p>
                              <p
                                className="fst-italic btn btn-light"
                                onClick={() =>
                                  handleProfile(taskDetails.task_author_id)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                Check User profile to see ratings.
                              </p>
                            </>
                          )}
                      </>
                    )}
                  </div>
                  <div className="col-12 col-lg-6 p-4">
                    <p className="text-muted fst-italic m-0">Status:</p>
                    <p className="fs-5">{taskDetails.task_status}</p>
                    <p className="text-muted fst-italic m-0">Category:</p>
                    <p className="fs-5">{taskDetails.task_category}</p>
                    <p className="text-muted fst-italic m-0">
                      Estimated Time of Work:
                    </p>
                    <p className="fs-5">
                      {taskDetails.task_deadline} {taskDetails.task_hourmin}
                    </p>
                    <p className="text-muted fst-italic m-0">Budget:</p>
                    <p className="fs-5">PHP {taskDetails.task_price}</p>
                    <p className="text-muted fst-italic m-0">Location:</p>
                    <p className="fs-5">{taskDetails.task_location}</p>
                    <p className="text-muted fst-italic m-0">Date Posted:</p>
                    <p className="fs-5">
                      {formatTaskDate(taskDetails.date_created)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="row container d-flex justify-content-center mt-5">
            <div class="loader">
              <div class="loader-text">Loading...</div>
              <div class="loader-bar"></div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ViewBrowseJobs;
