import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import Logo from "../assets/Pa-help-logo-icon.png";
import './components.scss';
import { UserAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import LogoName from '../assets/Pa-help-logo-name.png';
import GoogleButton from 'react-google-button';
import IconWork from '../assets/work.png';
import {
  getDoc,
  doc,
  collection,
} from "firebase/firestore";
import { database } from '../firebase'


function Login() {
  const { googleSignIn, facebookSignIn, user, loading, } = UserAuth();
  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  const [isloading, setIsLoading] = useState(false);


  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    console.log(user);
    console.log('1');

    // Check if user is not null and has a UID
    if (!loading && user) {
      console.log('2');
      const fetchUserData = async () => {
        console.log('3');
        setIsLoading(true)
        try {
          console.log('4');
          const userDocRef = doc(collection(database, "accounts"), user.uid);
          const userDocSnap = await getDoc(userDocRef);
          // console.log(userDocSnap.data())
          console.log('5');

          if (userDocSnap.exists()) {
            const data = userDocSnap.data();
            // Check if data exists before accessing its properties
            if (data) {
              setUserData(data.is_Accepted_TaC);
            } else {
              // Set userData to a default value if data is undefined
              setUserData(false); // or any other default value
            }
          } else {
            // Handle the case when the user document doesn't exist
            // Here you might want to create the user document or take appropriate action
            console.log('document not found')
          }
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false)
          console.error("Error fetching user data:", error);
        }
      };
      console.log('6');

      fetchUserData(); // Call the function to fetch user data
      console.log('8');
    }
  }, [user, loading, navigate]);

  useEffect(() => {
    console.log(userData);
    if (userData == false) {
      navigate('/termsAndCondition');
    }
    if (userData == true) {
      navigate('/allerrands');
    }
    // setLoading(false);
  }, [userData, user]);

  return (
    <div className="login-container">
      {
        loading || isloading ? (
          <div className="login-loader">
            <label>{isloading ? 'Signing in...' : 'Loading...'}</label>
            <div className="login-loading"></div>
          </div>
        ) : (
          <>
            <div className="login-form-container">
              <div className="login-title">
                <img src={Logo} alt="Logo" className="login-title-logo" />
                <img src={LogoName} alt="Logoname" className="login-title-logoname" />
              </div>
              <div className="SignIn-button">
                <GoogleButton onClick={handleGoogleSignIn} />
              </div>
              <div class="terms mt-3">
                <p class="tac">Read our <Link to={'/termsAndConditionReadOnly'} target="_blank" rel="noopener noreferrer">Terms and Conditions</Link> and  <Link to={'/privacyPolicyRead '} target="_blank" rel="noopener noreferrer">Privacy Policy</Link></p>
              </div>
            </div>
            <div className="split-container">
              <h1 className="text-title">" Your City's Personal Concierge "</h1>
              <div className="logo-work">
                <img src={IconWork} alt="IconWork" className="work-img" />
              </div>
            </div>
          </>
        )
      }
    </div>
  );
}

export default Login