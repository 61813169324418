import React from 'react'
import { useNavigate } from 'react-router-dom';
import './pages.scss';
import Sidebar from '../components/Sidebar.jsx'
import Header from '../components/Header.jsx';
import NoTask from "../assets/NoTask.svg";
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { database } from '../firebase.js';
import { UserAuth } from '../context/AuthContext';
import { BiEdit } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import { TiThList } from "react-icons/ti";
import { BiRefresh } from "react-icons/bi";
import { MdOutlineHistory } from "react-icons/md";
import { PiCheckSquareOffsetBold } from "react-icons/pi";
import { MdOutlineAddToPhotos } from "react-icons/md";
import { LuFileEdit, LuFilePieChart, LuFileKey2, LuFilePlus2 } from "react-icons/lu";
import { LuFileMinus2 } from "react-icons/lu";
import { IoGrid } from "react-icons/io5";
import { FaThList } from "react-icons/fa";

function MyJobOffers() {
    const navigate = useNavigate();
    const { user } = UserAuth();
    const [tasks, setTasks] = useState([]);
    const [open, setOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [jobStatus, setJobStatus] = useState('Open');
    const [selectedCategory, setSelectedCategory] = useState('Open');
    const [loading, setLoading] = useState(true);
    const [view, setView] = useState(1);

    const taskCateg = [

        {
            title: "Open",
            status: "Open",
            icon: <LuFileEdit />,
        },
        {
            title: "Standby",
            status: "Standby",
            icon: <LuFileMinus2 />,
        },
        {
            title: "In Progress",
            status: "In Progress",
            icon: <LuFilePieChart />,
        },
        {
            title: "Confirming",
            status: "Confirming",
            icon: <LuFileKey2 />,
        },
        {
            title: "History",
            status: "Completed",
            icon: <MdOutlineHistory />,
        },
    ]

    const handleOpen = () => {
        setOpen(!open)
    }

    //passing taskId to other component
    const handleTaskClick = (taskId) => {
        // Store the taskId in sessionStorage
        navigate('/viewerrandoffers');
        sessionStorage.setItem('taskId', taskId);
    };

    const handleUpdateTask = (taskId) => {
        // Store the taskId in sessionStorage
        sessionStorage.setItem('taskId', taskId);
        navigate('/addupdatejob');
    };

    // Function to format the date
    const formatTaskDate = (timestamp) => {
        const currentDate = new Date();
        const taskDate = new Date(timestamp.seconds * 1000);

        // Calculate the time difference in milliseconds
        const timeDifference = currentDate - taskDate;

        // If less than 1 minute ago, display "Just now"
        if (timeDifference < 60 * 1000) {
            return 'Just now';
        } else if (timeDifference < 60 * 60 * 1000) {
            // If less than 1 hour ago, display "X minutes ago"
            const minutesAgo = Math.floor(timeDifference / (60 * 1000));
            return `${minutesAgo} ${minutesAgo === 1 ? 'minute' : 'minutes'} ago`;
        } else if (timeDifference < 24 * 60 * 60 * 1000) {
            // If less than 24 hours ago, display "X hours ago"
            const hoursAgo = Math.floor(timeDifference / (60 * 60 * 1000));
            return `${hoursAgo} ${hoursAgo === 1 ? 'hour' : 'hours'} ago`;
        } else {
            // Otherwise, display the date in the format "Mon. DD, YYYY"
            const options = { month: 'short', day: 'numeric', year: 'numeric' };
            return taskDate.toLocaleDateString('en-US', options);
        }
    };


    //getting all the posted jobs of the user
    useEffect(() => {
        const userId = sessionStorage.getItem('userId');
        sessionStorage.removeItem('taskId');

        const fetchTasks = async () => {
            if (userId) {
                // Create a query to get tasks where task_author_id is equal to userId
                const q = query(
                    collection(database, 'tasks'),
                    where('task_author_id', '==', userId)
                );

                try {
                    // Execute the query
                    const querySnapshot = await getDocs(q);

                    // Extract task data from the query snapshot
                    const tasksData = querySnapshot.docs.map(doc => ({
                        taskId: doc.id,
                        ...doc.data()
                    }));

                    // Sort tasks by date in descending order
                    tasksData.sort((a, b) => b.date_created.seconds - a.date_created.seconds);

                    setTasks(tasksData);
                    console.log(tasksData);
                } catch (error) {
                    console.error('Error fetching tasks:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchTasks();
    }, []);

    const handleCategoryClick = (status) => {
        setSelectedCategory(status);
        console.log(selectedCategory)
        setJobStatus(status);

        // Add your logic here for handling the click event
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredTasks = tasks
        .filter(task => task.task_status === jobStatus)
        .filter(task =>
            task.task_title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            task.task_category.toLowerCase().includes(searchQuery.toLowerCase()) ||
            task.task_location.toLowerCase().includes(searchQuery.toLowerCase())
        );

    return (
        <>
            <div className="container">
                <div class="input-group my-4">
                    <span class="input-group-text text-muted border border-end-0"><FaSearch /></span>
                    <div class="form-floating">
                        <input type="text" class="form-control bg-light border border-start-0"
                            id="floatingInputGroup1"
                            placeholder="Username"
                            value={searchQuery}
                            onChange={handleSearchChange} />
                        <label for="floatingInputGroup1">Search</label>
                    </div>
                </div>

                <div class="row d-none d-lg-flex justify-content-around mb-4">
                    {taskCateg.map((item, index) => {
                        return (
                            <div class={`col-2 ${selectedCategory == item.status ? "text-warning border-3 border-warning border-bottom" : ""} text-center smooth`} style={{ cursor: "pointer" }} key={index} onClick={() => handleCategoryClick(item.status)}>
                                <p class="fs-4 mb-2" aria-current="page" href="#">{item.icon} {item.title}</p>
                            </div>
                        )
                    })}
                </div>
                <div class="row d-flex d-lg-none justify-content-around mb-4">
                    {taskCateg.map((item, index) => {
                        return (
                            <div class={`col-2 ${selectedCategory == item.status ? "text-warning border-3 border-warning border-bottom" : ""} text-center smooth`} style={{ cursor: "pointer" }} key={index} onClick={() => handleCategoryClick(item.status)}>
                                <p class="fs-4 mb-2" aria-current="page" href="#">{item.icon}</p>
                            </div>
                        )
                    })}
                </div>
                <div className="d-flex justify-content-end mb-4">
                    <div class="btn-group" role="group" aria-label="Basic example">
                        <button type="button" class={`btn d-flex align-items-center ${view ? "btn-warning text-light " : "btn-outline-warning "}`} onClick={() => setView(1)}><IoGrid className='me-2' /> Grid View</button>
                        <button type="button" class={`btn d-flex align-items-center ${view ? "btn-outline-warning " : "btn-warning text-light "}`} onClick={() => setView(0)}><FaThList className='me-2' /> List View</button>
                    </div>
                </div>

                <div className={`row ${view ? "" : "d-flex justify-content-center"}`}>
                    {
                        loading ? (
                            <div className="loader">
                                <div className="loader-text">Loading...</div>
                                <div className="loader-bar"></div>
                            </div>
                        ) : (
                            filteredTasks.length == 0 ? (
                                <div className='NoTask-container'>
                                    <img src={NoTask} className='NoTask-img' alt='NoTask'></img>
                                    <p className='NoTask-text'>Empty Task</p>
                                </div>
                            ) : (
                                <>
                                    {
                                        view ?
                                            <>
                                                {filteredTasks.map(task => (
                                                    <div key={task.taskId} className="col-12 col-lg-4 py-3 px-4" style={{cursor: "pointer"}} onClick={()=>handleTaskClick(task.taskId)}>
                                                        <div className="card row">
                                                            <div className="col-12 bg-warning p-3 pb-1">
                                                                <h5 class="card-title m-0">{task.task_title}</h5>
                                                                <div class="col-12 fw-semibold card-subtitle text-muted m-0">{task.task_author}</div>
                                                            </div>
                                                            <p class="col-12 text-overflow-clamp pt-1 m-0">{task.task_description}</p>
                                                            <div className="col-12 my-2">
                                                                <span className="text-light text-center bg-warning px-2 py-1 rounded-pill me-2">{task.task_category}</span>
                                                            </div>
                                                            <div class="col-12 card-text text-muted fst-italic m-0 mt-2">{formatTaskDate(task.date_created)}</div>
                                                        </div>
                                                    </div>
                                                ))}
                                                {/* <div className="col-12 col-lg-4 py-3 px-4">
                                                    <div className="card row">
                                                        <div className="col-12 bg-warning p-3 pb-1">
                                                            <h5 class="card-title m-0">Bootstrap 5 Card</h5>
                                                            <div class="col-12 fw-semibold card-subtitle text-muted m-0">Joshua Ayran</div>
                                                        </div>
                                                        <p class="col-12 text-overflow-clamp pt-1 m-0">A card is a flexible and extensible content container. It includes options for headers and footers, a wide variety of content, contextual background colors, and powerful display options. If you’re familiar with Bootstrap 3, cards replace our old panels, wells, and thumbnails. Similar functionality to those components is available as modifier classes for cards</p>
                                                        <div className="col-12 my-2">
                                                            <span className="text-light text-center bg-warning px-2 py-1 rounded-pill me-2">paper works</span>
                                                        </div>
                                                        <div class="col-12 card-text text-muted fst-italic m-0 mt-2">1 hour ago</div>
                                                    </div>
                                                </div> */}
                                            </>
                                            :
                                            <>
                                                {filteredTasks.map(task => (
                                                    <div key={task.taskId} className="col-12 col-lg-10 py-2 px-4" style={{cursor: "pointer"}} onClick={()=>handleTaskClick(task.taskId)}>
                                                        <div className="card">
                                                            <div className="row container-fluid">
                                                                <div className="col-1 bg-warning">
                                                                </div>
                                                                <div className="col-11 py-3">
                                                                    <h5 class="card-title ">{task.task_title}</h5>
                                                                    <div class="col-12 fw-semibold card-subtitle text-muted ">{task.task_author}</div>
                                                                    <p class="col-12 text-overflow-clamp mb-1">{task.task_description}</p>
                                                                    <div className="col-12 my-2">
                                                                        <span className="text-light text-center bg-warning px-2 py-1 rounded-pill me-2">{task.task_category}</span>
                                                                    </div>
                                                                    <div class="col-12 card-text text-muted fst-italic mt-2">{formatTaskDate(task.date_created)}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                                {/* <div className="col-12 col-lg-10 py-2 px-4">
                                                    <div className="card">
                                                        <div className="row container-fluid">
                                                            <div className="col-1 bg-warning">
                                                            </div>
                                                            <div className="col-11 py-3">
                                                                <h5 class="card-title ">Card title</h5>
                                                                <div class="col-12 fw-semibold card-subtitle text-muted ">Jymcali Ocampo</div>
                                                                <p class="col-12 text-overflow-clamp mb-1">
                                                                    This text is quite long, and will be truncated once displayed. Some quick example text to build on the card title and make up the bulk of the card's content.
                                                                </p>
                                                                <div className="col-12 my-2">
                                                                    <span className="text-light text-center bg-warning px-2 py-1 rounded-pill me-2">paper works</span>
                                                                </div>
                                                                <div class="col-12 card-text text-muted fst-italic mt-2">1 hour ago</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </>
                                    }

                                </>
                            )
                        )}
                </div>
            </div>
        </>
    )
}

export default MyJobOffers