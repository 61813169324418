import { Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import { AuthContextProvider } from "./context/AuthContext.js";
import Protected from "./components/Protected.js";
import Login from "./components/Login.jsx";
import TACdetails from "./components/TACdetails.jsx"
import TACreadonly from "./components/TACreadonly.jsx"
import PPreadonly from "./components/PPreadonly.jsx"
import TermsAndCondition from "./components/TermsAndCondition.jsx"
import PPdetails from "./components/PPdetails.jsx"
import BrowseJobs from "./pages/BrowseJobs";
import MyJobOffers from "./pages/MyJobOffers.jsx";
import AppliedJobs from "./pages/AppliedJobs.jsx";
import Messages from "./pages/Messages.jsx";
import ViewJobOffers from "./pages/ViewJobOffers.jsx";
import ViewBrowseJobs from "./pages/ViewBrowseJobs.jsx";
import AddUpdateJobs from "./pages/AddUpdateJobs.jsx";
import Profile from "./pages/Profile.jsx";
import ProfileCheck from "./pages/ProfileCheck.jsx";
import SetFirstProfile from "./components/SetFirstProfile.jsx";
import FAQ from "./components/FAQ.jsx";
import Layout from "./components/Layout.jsx"
import PrivacyPolicy from "./components/PrivacyPolicy.jsx";
import DataPrivacyPolicy from "./components/DataPrivacyPolicy.jsx";

function App() {
  return (
    <div className="App">
      <AuthContextProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/privacyPolicy"
            element={
              <PPdetails />
            }
          />
          <Route
            path="/termsAndCondition"
            element={
              <TermsAndCondition />
            }
          />
          <Route
            path="/setFirstProfile"
            element={
              <SetFirstProfile />
            }
          />
            <Route
              path="/termsAndConditionReadOnly"
              element={
                <TACreadonly />
              }
            />
            <Route
              path="/privacyPolicyRead "
              element={
                <PrivacyPolicy />
              }
            />
            <Route
              path="/messages/:applicantId"
              element={
                <Protected>
                  <Messages />
                </Protected>
              }
            />
          <Route path='/' element={<Layout />}>
          <Route
            path="/dataPrivacyPolicy"
            element={
              <DataPrivacyPolicy/>
            }
          />
            <Route
              path="/privacyPolicyReadOnly"
              element={
                <PPreadonly />
              }
            />
            <Route
              path="/termsAndConditionRead"
              element={
                <TACdetails />
              }
            />
            <Route
              path="/profile"
              element={
                <Protected>
                  <Profile />
                </Protected>
              }
            />
            <Route
              path="/profilecheck/:userId"
              element={
                <Protected>
                  <ProfileCheck />
                </Protected>
              }
            />

            <Route
              path="/allerrands"
              element={
                <Protected>
                  <BrowseJobs />
                </Protected>
              }
            />
            <Route
              path="/errandoffers"
              element={
                <Protected>
                  <MyJobOffers />
                </Protected>
              }
            />
            <Route
              path="/appliederrands"
              element={
                <Protected>
                  <AppliedJobs />
                </Protected>
              }
            />
            <Route
              path="/messages"
              element={
                <Protected>
                  <Messages />
                </Protected>
              }
            />

            <Route
              path="/viewerrandoffers"
              element={
                <Protected>
                  <ViewJobOffers />
                </Protected>
              }
            />
            <Route
              path="/viewerrandinfo"
              element={
                <Protected>
                  <ViewBrowseJobs />
                </Protected>
              }
            />
            <Route
              path="/addupdatejob"
              element={
                <Protected>
                  <AddUpdateJobs />
                </Protected>
              }
            />
            <Route
              path="/faq"
              element={
                <FAQ />
              }
            />
          </Route>
          <Route path="*" element={<Navigate to="/allerrands" />} />
        </Routes>
      </AuthContextProvider>
    </div>
  );
}

export default App;
