import React, { useState, useEffect } from 'react'
import Sidebar from '../components/Sidebar.jsx'
import Header from '../components/Header.jsx';
import { Link } from 'react-router-dom'

function TACdetails() {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(!open)
    }

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1 className='text-warning text-center'>TERMS AND CONDITIONS</h1>
                    </div>
                    <div className="col-12">
                        <div className='tac-container'>
                            <p className='tac-center'>Pa-Help operates an online platform facilitating Users to connect through the Pa-Help Platform with other Users offering Assistance.</p>
                            <p className='tac-center'>Before using the Pa-Help Platform, please thoroughly review these terms and all associated Policies, including the <Link to={'/privacyPolicyReadOnly '}>Privacy Policy</Link>. These Policies are integral parts of this Agreement by reference. If you have any further inquiries, <Link to={'https://web.facebook.com/profile.php?id=61556142215229'} target="_blank" rel="noopener noreferrer">please reach out to us here.</Link></p>
                            <p className='tac-center'>All terms defined in this Agreement carry the meanings ascribed to them in the Pa-Help Glossary.</p>

                            <h2>1. SCOPE OF PA-HELP SERVICES</h2>
                            <h3>Basic Principles</h3>

                            <p>1.1 Pa-Help Platform:<br />
                                Pa-Help provides the Pa-Help Platform, enabling Users to post Tasks for assistance.
                            </p>
                            <p>1.2 Assistance Offers:<br />
                                Helpmates can submit Assistance Offers in response to a Task, and certain Offer details may be accessible publicly.
                            </p>
                            <p>1.3 Task Modification or Revocation:<br />
                                A User can modify or revoke a posted Task at any time before accepting an Offer. Pa-Help reserves the right to cancel all Offers on a Task made before the modification</p>
                            <p>1.4 Task Agreement:<br />
                                Upon accepting an Offer on the Pa-Help Platform, a Task Agreement is established between the Helpmate and the User.
                            </p>
                            <p>1.5 Compensation Transfer:<br />
                                Upon Task Agreement, the User is required to transfer the Agreed Compensation directly to the Helpmate.
                            </p>
                            <p>1.6 Task Agreement Adjustments:<br />
                                Once the Task Agreement is formed, Users may adjust it on the Pa-Help Platform. Users and Helpmates are encouraged to use Pa-Help’s private messaging system for any amendments or communications.
                            </p>
                            <p>1.7 Notification of Task Completion:<br />
                                After completing the services, the Helpmate must notify the User of completion on the Pa-Help Platform.
                            </p>
                            <p>1.8 User Confirmation:<br />
                                Following service completion, the User must confirm it on the Pa-Help Platform.
                            </p>
                            <p>1.9 Task Completion Verification:<br />
                                Upon confirmation of the completed Task by the User or Pa-Help's satisfaction, the Helpmate can consider the Task complete, and the agreed Compensation is deemed fulfilled.
                            </p>
                            <p>1.10 Feedback and Reviews:<br />
                                After the Task Agreement is fulfilled, both parties are encouraged to provide feedback and reviews of the services on the Pa-Help Platform.
                            </p>
                            <p>This revision reflects the absence of a Payment Account and emphasizes the completion verification step without involving a payment system.
                            </p>

                            <h2>2. ROLE AND OBLIGATIONS</h2>

                            <p>2.1 Pa-Help Platform Provision:<br />
                                Pa-Help operates as a platform, providing Users with a space to post and engage in assistance Tasks.
                            </p>
                            <p>
                                2.2 User Eligibility:
                                Pa-Help permits individuals above 18 years of age to become Users. Accounts created with false information or on behalf of someone under 18 are violations of our terms.
                            </p>
                            <p>
                                2.3 Account Creation:<br />
                                Creating and establishing an account with Pa-Help is free of charge. Users can specify within their account description if they represent a business entity.
                            </p>
                            <p>
                                2.4 Account Management:<br />
                                Pa-Help may refuse, cancel, suspend, or modify any account at its discretion, especially if the User's conduct is deemed detrimental to the platform's operation.
                            </p>
                            <p>
                                2.5 Liability Limitation:<br />
                                Pa-Help accepts no liability for the Citizen and Helpmate interaction, including the description, performance, or delivery of services. Pa-Help disclaims any implied warranties and provides the platform on an "as-is" basis
                            </p>
                            <p>
                                2.6 Dispute Resolution:<br />
                                Pa-Help has no obligation to involve itself in disputes between Users. Users are encouraged to resolve issues independently.
                            </p>
                            <p>
                                2.7 Background Checks:<br />
                                Pa-Help does not undertake any investigation on Helpmates before admission to the platform. Users are responsible for conducting appropriate background checks and assessments before engaging a Helpmate.
                            </p>
                            <p>
                                2.8 User Responsibility:<br />
                                Users understand and agree to assume all risks associated with engaging Helpmates. Pa-Help is released from any liability arising from the services provided.
                            </p>
                            <p>
                                2.9 Modification of Terms:<br />
                                Pa-Help reserves the right to modify terms and conditions. Users are encouraged to review policies regularly.
                            </p>
                            <p>
                                This draft outlines the key roles and responsibilities of Pa-Help, emphasizing user eligibility, account management, and the platform's role in facilitating assistance Tasks.
                            </p>

                            <h2>3. USER OBLIGATIONS</h2>
                            <p>
                                3.1 Accurate Information:<br />
                                Users must provide accurate and truthful information during the account creation process. Creating an account with false information is a violation of our terms.
                            </p>
                            <p>
                                3.2 Responsible Conduct:<br />
                                Users are expected to conduct themselves responsibly and adhere to ethical behavior when posting Tasks or responding to Assistance Offers.
                            </p>
                            <p>
                                3.3 Compliance with Policies:<br />
                                Users are required to comply with all Pa-Help policies, including the Community Guidelines and Privacy Policy. Violation of policies may result in account suspension or termination.
                            </p>
                            <p>
                                3.4 Task Modification or Revocation:<br />
                                Users can modify or revoke posted Tasks at any time before accepting an Offer. Pa-Help reserves the right to cancel all Offers on a Task made before the modification.
                            </p>
                            <p>
                                3.5 Transparent Communication:<br />
                                Users are encouraged to communicate transparently and use Pa-Help's private messaging system for any amendments or communications related to a Task Agreement.
                            </p>
                            <p>
                                3.6 Timely Confirmation:<br />
                                Upon completing the services, the Helpmate must promptly notify the User of completion on the Pa-Help Platform. Similarly, the User must confirm the completion of the Task promptly.
                            </p>
                            <p>
                                3.7 Feedback and Reviews:<br />
                                After the Task Agreement is fulfilled, both Users and Helpmates are encouraged to provide constructive feedback and reviews of the services on the Pa-Help Platform.
                            </p>
                            <p>
                                3.8 Responsible Use of Information:<br />
                                Users are responsible for the responsible use of any information shared during the assistance process. Confidential or sensitive information should not be disclosed.
                            </p>
                            <p>
                                3.9 Adherence to Local Laws:<br />
                                Users must adhere to local laws and regulations when posting Tasks or responding to Assistance Offers. Pa-Help disclaims any liability for users engaging in illegal or prohibited activities.
                            </p>
                            <p>
                                3.10 Ethical Conduct:<br />
                                Users are expected to engage in ethical conduct, respecting the rights and dignity of others. Discriminatory, offensive, or harmful behavior is strictly prohibited.
                            </p>
                            <p>
                                3.11 Continuous Compliance:<br />
                                Users must continuously comply with Pa-Help's User Agreement and any updates or modifications to ensure a safe and secure platform environment.
                            </p>
                            <p>
                                This draft outlines the essential obligations expected from users engaging with Pa-Help, emphasizing responsible conduct, compliance with policies, and transparent communication.
                            </p>


                            <h2>4. THIRD-PARTY SERVICES</h2>
                            <p>Pa-Help operates as a hyperlocal cross-platform app, and as such, it does not incorporate third-party services for payment processing or other functionalities. Unlike platforms that may rely on external systems for various services, Pa-Help streamlines its operations to ensure a direct and secure interaction between Users and Helpmates.
                                <br /><br />
                                Key Points:
                            </p>
                            <p>
                                4.1 Text Code Confirmations:
                                Pa-Help employs a text code system for confirming transactions. Users and Helpmates utilize unique codes within the app to validate task completion and compensation, ensuring a secure and user-friendly confirmation process.
                            </p>
                            <p>
                                4.2 User-Managed Transactions:
                                Users have the autonomy to manage transactions directly with Helpmates without the involvement of third-party payment processors. This approach simplifies the process, offering a more personalized and user-centric experience.
                            </p>
                            <p>
                                4.3 Privacy and Security:<br />
                                By not integrating third-party services for payment or other functionalities, Pa-Help enhances privacy and security for its users. The app's design prioritizes user control and data protection in every transaction
                            </p>
                            <p>
                                4.4 Localized Operations:<br />
                                Pa-Help's focus on a hyperlocal approach eliminates the need for extensive third-party involvement. Users and Helpmates engage in transactions within their local vicinity, fostering a community-driven and trust-based environment.
                            </p>
                            <p>
                                4.5 Direct Interaction:<br />
                                Users can engage directly with Helpmates, confirming task completion and compensation within the app. This direct interaction minimizes the reliance on external services, offering a seamless and efficient user experience.
                            </p>
                            <p>
                                By avoiding third-party services, Pa-Help ensures a more straightforward and user-friendly platform, allowing Users and Helpmates to transact with confidence and convenience. The app's localized and personalized approach enhances the overall user experience.
                            </p>

                            <h2>5. VERIFICATION & BADGES</h2>
                            <p>Pa-Help prioritizes the authenticity and credibility of user accounts through a robust verification process. The following outlines the verification system and the corresponding badges:</p>
                            <p>
                                5.1 Email Authentication:<br />
                                Users are required to authenticate their accounts through a secure email verification process. This initial step ensures the legitimacy of user registrations, enhancing overall platform security.

                            </p>
                            <p>
                                5.2 OCR Document Verification:<br />
                                To elevate user trust and safety, Pa-Help utilizes Optical Character Recognition (OCR) technology for the verification of official documents, such as identification cards and other necessary credentials. This advanced technology ensures accurate and efficient verification
                            </p>
                            <p>
                                5.3 Verified Account:<br />
                                Upon successful completion of the verification process, users will be awarded a "Verified Account" status. This badge serves as a visible acknowledgment of the user's authenticity, providing increased trust for both Users and Helpmates.

                            </p>
                            <p>
                                5.4 Trust and Safety Assurance:<br />
                                The verification badges contribute to a safer and more reliable community by reducing the risk of fraudulent activities. Users can confidently engage with others who have undergone the verification process.

                            </p>
                            <p>
                                5.5 Transparent Identification:<br />
                                Users with verified accounts display a badge prominently on their profiles, offering transparency to the Pa-Help community. This transparency fosters a sense of security and encourages responsible interactions.

                            </p>
                            <p>
                                5.6 Continuous Monitoring:<br />
                                Pa-Help employs a continuous monitoring system to ensure the ongoing integrity of verified accounts. Any suspicious activities or changes in user behavior trigger additional security measures.

                            </p>
                            <p>
                                By implementing a comprehensive verification system and associated badges, Pa-Help aims to create a secure and trustworthy environment for all users. The process not only enhances the overall safety of the platform but also encourages responsible and authentic interactions within the community.
                            </p>

                            <h2>6. USER FEEDBACK SYSTEM</h2>
                            <p>Pa-Help values the opinions and experiences of its users, fostering a collaborative and constructive community. The feedback system plays a crucial role in enhancing transparency, accountability, and overall satisfaction. The following outlines the key components of the Pa-Help feedback system:
                            </p>
                            <p>
                                6.1 Two-Way Feedback:<br />
                                Pa-Help encourages a two-way feedback mechanism, allowing both Users and Helpmates to provide insights about their collaborative experiences. This bidirectional communication promotes fairness and comprehensive evaluations.

                            </p>
                            <p>
                                6.2 Post-Task Reviews:<br />
                                After the completion of a task, Users and Helpmates can submit reviews about each other. This post-task feedback system serves as a valuable tool for assessing the quality of services, reliability, and professionalism.

                            </p>
                            <p>
                                6.3 Star Ratings:<br />
                                Users can assign star ratings to Helpmates based on their overall satisfaction with the completed task. The star rating system provides a quick and visual representation of a user's performance, aiding others in their decision-making process.

                            </p>
                            <p>
                                6.4 Comment Section:<br />
                                A dedicated comment section allows Users and Helpmates to provide detailed feedback beyond star ratings. This open communication channel enables users to share specific experiences, commend exceptional service, or address any concerns.

                            </p>
                            <p>
                                6.5 Continuous Improvement:<br />
                                Pa-Help utilizes the feedback received to make continuous improvements to the platform. User insights are valuable in refining the user experience, addressing issues promptly, and implementing features that align with user expectations.
                            </p>
                            <p>
                                6.6 Trustworthiness Recognition:<br />
                                Users with consistently positive feedback and high star ratings earn a reputation for trustworthiness. This recognition enhances their profile visibility and encourages other users to engage in tasks with them confidently.

                            </p>
                            <p>
                                6.7 Community Guidelines Compliance:<br />
                                The feedback system is aligned with Pa-Help's community guidelines. Users and Helpmates are encouraged to adhere to these guidelines, fostering a respectful and cooperative environment.

                            </p>
                            <p>
                                6.8 Dispute Resolution:<br />
                                In the event of disputes, the feedback system provides valuable context for dispute resolution. Pa-Help utilizes this information to address conflicts and maintain a fair and reliable platform.

                            </p>
                            <p>
                                By actively participating in the feedback system, users contribute to the creation of a vibrant and trustworthy Pa-Help community. The system not only serves as a tool for individual improvement but also shapes the overall quality of interactions within the platform.

                            </p>

                            <h2>7. LIMITATION OF LIABILITY</h2>
                            <p>Please see the Philippines’ Specific Terms for the applicable exclusions and limitations of liability.
                            </p>

                            <h2>8. PRIVACY
                            </h2>
                            <p>
                                8.1. Collection of Information:<br />
                                Pa-Help, operated by SynthX, collects essential user information during registration and platform usage. This includes data necessary for identification, authentication, and efficient interaction between Users and Helpmates.

                            </p>
                            <p>
                                We are committed to safeguarding your privacy and adhering to the guidelines set by the National Privacy Commission's Data Privacy Act of 2012 (Republic Act No. 10173).
                            </p>

                            <h2>
                                9. MODIFICATIONS TO THE AGREEMENT

                            </h2>
                            <p>
                                Pa-Help reserves the right to modify, amend, or update this agreement to enhance user experience, comply with legal requirements, or address evolving needs. Users will be notified of any changes through their accounts or registered email addresses. Continued use of the Pa-Help platform after modifications implies acceptance of the updated terms and conditions.
                            </p>
                            <h2>
                                10. NO AGENCY
                            </h2>
                            <p>
                                This agreement does not establish any agency or partnership between Pa-Help and its users. Users acknowledge that no joint venture, employment, or agency relationship exists, and neither party holds the authority to bind the other without explicit written consent.
                            </p>
                            <h2>
                                11. GENERAL
                            </h2>
                            <p>
                                11.1 ACCEPTANCE OF TERMS<br />
                                By accessing and using Pa-Help's platform, users automatically agree to abide by these general terms and conditions. Any violation may result in penalties or account suspension.

                            </p>
                            <p>
                                11.2 USER CONDUCT<br />
                                Users are expected to conduct themselves in a manner that upholds the integrity of the Pa-Help community. Any behavior deemed inappropriate, abusive, or violating the community guidelines may lead to account sanctions.

                            </p>
                            <p>
                                11.3 ACCOUNT RESPONSIBILITY<br />
                                Users are solely responsible for the security of their accounts. Pa-Help will not be held liable for any unauthorized access, and users should take necessary measures to protect their login credentials.

                            </p>

                            <h2>Glossary of Terms for Pa-Help</h2>
                            <p>
                                ”Pa-Help Platform” Refers to the entire digital environment provided by Pa-Help, including the website and mobile application, where Users can post and accept Tasks.
                                <br /><br />
                                ”User” An individual accessing and utilizing Pa-Help's platform to either post Tasks or provide Assistance.
                                <br /><br />
                                ”Helpmate” A User who offers assistance to perform a Task posted by another User.
                                <br /><br />
                                ”Task” A job or service posted by a User on the Pa-Help platform, for which assistance is sought.
                                <br /><br />
                                ”Assistance Offer” The response submitted by a Helpmate expressing their willingness to perform a Task.
                                <br /><br />
                                ”Task Agreement” The mutual acceptance between a User and a Helpmate to proceed with the execution of a Task.
                                <br /><br />
                                ”Agreed Compensation” The predefined payment decided upon the acceptance of a Task Agreement.
                                <br /><br />
                                ”Payment Account” A designated account where the Agreed Compensation is transferred for the completion of a Task.
                                <br />
                                ”Service Fee” The fee charged by Pa-Help for facilitating the Task Agreement and providing its services.
                                <br /><br />
                                ”Task Completion” The acknowledgment of a completed Task by the User or Pa-Help, triggering the release of Compensation to the Helpmate.
                                <br /><br />
                                ”Feedback” Comments and ratings provided by Users and Helpmates after the completion of a Task, contributing to the reputation of each party.
                                <br /><br />
                                ”Verification & Badges” The process of authenticating a user's account through email and OCR verification of identification documents, resulting in a verified account or badge.
                                <br /><br />
                                ”Text Codes” A method of confirmation used in place of QR codes, involving the use of alphanumeric codes for task verification.
                                <br /><br />
                                ”NPC” National Privacy Commission, referring to the regulatory body overseeing data privacy in the Philippines.
                                <br /><br />
                                ”OCR” Optical Character Recognition, a technology used for extracting text from images, in this context, utilized for document verification.
                                <br /><br />
                                ”Community Guidelines” Prescribed rules and norms governing user behavior and interactions within the Pa-Help community.
                                <br /><br />
                                ”Account Sanctions” Penalties imposed on a User for violations of Pa-Help's terms, ranging from warnings to account suspension.

                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </>

    )
}

export default TACdetails