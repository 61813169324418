import React from 'react'
import { Link } from 'react-router-dom'

function PPdetails() {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1 className='text-warning text-center'>PRIVACY POLICY</h1>
                    </div>
                    <div className="col-12">
                        <div className="tac-container">
                            <p>
                                Pa-Help, operated by SynthX, a collective of student developers based in the Philippines,
                                prioritizes the protection and management of your information in compliance with the
                                regulations set forth by the National Privacy Commission (NPC) an independent body
                                created under Republic Act No. 10173 or the Data Privacy Act of 2012. In this Privacy Policy,
                                references to "Pa-Help," "we," "our," and/or "us" denote SynthX.<br /><br />
                                This Privacy Policy outlines how Pa-Help collects, utilizes, shares, and manages your
                                personal data, establishing the rights and responsibilities for both you and Pa-Help
                                concerning your personal information.<br /><br />
                                Upon accessing www.pa-help.site or our mobile application (collectively referred to as the
                                "Services"), you agree to adhere to the Terms and Conditions stipulated in Pa-Help's user
                                agreement ("User Agreement"). By doing so, you acknowledge that your personal data may
                                be gathered, utilized, and disclosed in accordance with this Privacy Policy. All terms not
                                explicitly defined herein shall carry the same meaning as defined in the User Agreement. It's
                                important to note that, per Pa-Help's Terms and Conditions, individuals under 18 years old
                                are restricted from using the Services.<br /><br />
                                Pa-Help reserves the right to occasionally amend this Privacy Policy, with any modifications
                                reflected on the respective webpage. In the event of substantial alterations in how Pa-
                                Help utilizes or shares your previously collected personal data, notifications will be provided
                                through your account, registered email address, or other communication channels.
                                Regularly checking your account is advised.<br /><br />
                                Please be attentive to updates, and for any queries or concerns, feel free to contact us.
                            </p>

                            <h2>1. Collection of Your Personal Data</h2>
                            <p>
                                Information we collect directly from you<br /><br />
                                In cases where the provided information below is not furnished, our ability to deliver the
                                Services and fulfill contractual obligations may be compromised.<br /><br />
                                When you register with Pa-Help, we collect personal data, including:
                            </p>
                            <ul>
                                <li>
                                    Your name, address, email address, phone number, and other contact details.
                                </li>
                                <li>
                                    Your birthdate, processed by the application.
                                </li>
                                <li>
                                    Your location and the location you're interested in for performing Posted Tasks.
                                </li>
                                <li>
                                    Your occupation, work experience, resume, qualifications, education, posted tasks,earn money preferences, skill set, interests, and other pertinent information related to your fitness for the posted tasks.
                                </li>
                            </ul>
                            <p>
                                Additionally, we offer the option to upload a photo to be associated with your Pa-Help
                                user ID. If there are changes to your personal details, it is your responsibility to update your
                                account, ensuring our records remain comprehensive, accurate, and up-to-date.<br /><br />
                                To enhance our existing services, introduce new features, and provide targeted marketing
                                communications to you and others, Pa-Help gathers information on how you use the
                                Services.<br /><br />
                                It's essential to note that Pa-Help neither controls nor assumes any responsibility or liability
                                for the disclosure or use of personal data voluntarily posted by you in publicly accessible
                                areas of the Services.
                            </p>

                            <h2>2. How We Use Your Personal Data
                            </h2>
                            <p>Pa-Help may utilize the collected information for the following purposes:</p>
                            <p>
                                1. Identification and Authentication:<br />
                                • This is essential to grant you access to the Services.
                            </p>
                            <p>
                                2. Security of Pa-Help and Users:<br />
                                • Ensuring the security of the Services is in the legitimate interests of both Pa-
                                Help and its users.
                                • We require some of your personal data to deliver optimal services, which
                                aligns with our legitimate interests.
                                It's crucial for us to create a safe environment for users, protecting the
                                integrity of the Services we provide.
                                Using your personal data in this manner is necessary to fulfill a contract
                                between you and Pa-Help.
                            </p>
                            <p>
                                3. Communication Records:<br />
                                • Records of communications between you and Pa-Help are retained to aid in
                                resolving any issues and are kept based on legal requirements and our
                                legitimate interests in protecting legal rights.
                            </p>
                            <p>
                                4. Display of Personal Data to Other Users:<br />
                                • If other users have your userID or identifying information, and you've
                                uploaded a photo or other personal data to your Pa-Help account, we may
                                show them that information.<br />
                                • Your photo is displayed to other users with your explicit consent, given when
                                you upload your photo.
                            </p>
                            <p>
                                5. User Reviews:<br />
                                • The Services enable you to review your interactions with others, and they
                                may reciprocate.<br />
                                • These reviews contribute to an aggregate rating publicly displayed along
                                with your profile, assisting other users in their decision-making process.
                                Please note that users may rely on this rating when determining whether to
                                engage with you.
                            </p>
                            <h2>
                                3. How We Share Your Personal Data
                            </h2>
                            <p>
                                Pa-help may disclose the information We collect from you as follows:<br />
                                • Sharing Between Users. We may share information, such as ratings and
                                reviews about you, with other users who are looking for Posted Tasks on the
                                Services.<br />
                                • You may share your personal data with any other third party as necessary to
                                deliver a service you requested.
                            </p>

                            <h2>
                                4. Security
                            </h2>
                            <p>
                                Your account is safeguarded by a password to ensure your privacy and security.
                                While we implement reasonable measures to protect your personal data from
                                unauthorized access, use, and disclosure, we cannot guarantee absolute security
                                or immunity from third-party interception or viruses in our systems. The encryption of
                                information sent from your computer to us over the Internet cannot be assured.
                                Consequently, we do not warrant the security or privacy of your personal data,
                                including payment and account details. The transmission of your personal data to
                                us is done at your own risk. It is your sole responsibility to uphold the security of your
                                passwords and/or account information.
                            </p>

                            <h2>
                                5. Third Parties
                            </h2>
                            <p>
                                Pa-help app does not utilize any third-party applications for processing user
                                information. All data and information are handled internally within the app, ensuring
                                the privacy and security of our users' information. We prioritize the confidentiality
                                and protection of user data throughout their interactions with our platform.
                            </p>

                            <h2>
                                6.Retention
                            </h2>
                            <p>
                                We keep your personal data for the duration required for the purposes for which it
                                was initially collected or lawfully processed. Additionally, we retain the data for a
                                period necessary to fulfill our legal obligations or to enable us to pursue, defend, or
                                exercise legal claims.
                            </p>

                            <h2>
                                7.Contact us
                            </h2>
                            <p>
                                If you have any questions about this Privacy Policy or about the manner in which we
                                process your personal data, please contact us:<br />
                                synthxgordoncollege@gmail.com
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PPdetails